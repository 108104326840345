import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../styles/colors';
import Fab from '@material-ui/core/Fab';
import { RouteComponentProps } from 'react-router';
import List, { Header } from '../List';
import EndUserStore, { EndUser } from '../../stores/endUserStore';
import { inject, observer } from 'mobx-react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Female from '../../assets/sex/gender-female.svg';
import Male from '../../assets/sex/gender-male.svg';
import ProjectStore from '../../stores/projectStore';
import GroupStore from '../../stores/groupStore';
import LocationStore from '../../stores/locationStore';
import AuthStore from '../../stores/authStore';
import Button from '@material-ui/core/Button';
import CartStore from '../../stores/cartStore';
import Filters from '../Filters';
import AppStore from '../../stores/appStore';

interface MatchParams {}

interface EndUserListProps extends WithTranslation {}

interface InjectedProps extends EndUserListProps, RouteComponentProps<MatchParams> {
  endUserStore: EndUserStore;
  authStore: AuthStore;
  projectStore: ProjectStore;
  groupStore: GroupStore;
  appStore: AppStore;
  locationStore: LocationStore;
  cartStore: CartStore;
  classes: any;
}

interface EndUserListState {
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
  groups: { label: string; value: any }[];
  locations: { label: string; value: any }[];
  cartEndUser: string[];
}

@inject('endUserStore', 'groupStore', 'locationStore', 'projectStore', 'authStore', 'cartStore', 'appStore')
@observer
class EndUserList extends Component<EndUserListProps, EndUserListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: {},
    sortParams: {},
    groups: [],
    locations: [],
    cartEndUser: []
  };

  removeFromCart = (e: any, endUser: any) => {
    e.stopPropagation();
    if (this.p.cartStore.cart.findIndex(item => item.id === endUser.id) !== -1) {
      this.p.cartStore.removeEndUserFromCartAction(endUser);
    }
  };

  headers = [
    {
      name: this.p.t('END_USER_LIST.ACTION'),
      key: 'action'
    },
    {
      name: this.p.t('END_USER_LIST.NAME'),
      key: 'firstName'
    },
    {
      name: this.p.t('END_USER_LIST.SURNAME'),
      key: 'lastName',
      sort: true
    },
    {
      name: this.p.t('END_USER_LIST.PID'),
      key: 'pid'
    },
    {
      name: this.p.t('END_USER_LIST.GROUP'),
      key: 'group'
    },
    {
      name: this.p.t('END_USER_LIST.LOCATION'),
      key: 'location'
    },
    {
      name: this.p.t('END_USER_LIST.GENDER'),
      key: 'gender'
    },
    {
      name: this.p.t('END_USER_LIST.CREATED_DATE'),
      key: 'createdDate'
    },
    {
      name: this.p.t('END_USER_LIST.CREATED_BY'),
      key: 'createdBy'
    }
  ];

  componentDidMount(): void {
    this.getCartList();
  }

  getCartList = (sortParams?: any) => {
    const params = {
      ...this.state.filters
    };
    this.p.cartStore.getCartListAction(params);
  };

  getCart = async () => {
    const success = await this.p.cartStore.getCartEndUserFromLocaleStorage();
    if (!success) {
      return this.p.history.replace('/end-users');
    }
  };

  submit = async (values: any) => {
    let sendCart = this.p.cartStore.cart.map(item => item.id);
    this.p.cartStore.sendCartAction(sendCart);
  };

  handleOpenEndUser = (endUser: EndUser) => () => {
    this.p.history.push(`/end-users/details/${endUser.id}`);
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getCartList({ page: 0 }));
  };

  render() {
    const filters = [
      {
        name: 'pid',
        label: this.p.t('END_USER_LIST.PID'),
        type: 'input'
      }
    ];
    const {
      classes,
      t,
      cartStore: { cart, cartTotalCount, filteredCart },
      appStore: { isFilter }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography className={classNames(classes.dividerFullWidth, 'title')} display="block" variant="subtitle1">
          {t('CART.TITLE')}
        </Typography>
        <Divider component="div" className={'divider'} />
        <Filters handleFilter={this.handleChange} filters={filters} />
        <List
          data={filteredCart}
          getData={this.getCart}
          headers={this.headers}
          isFilter={isFilter}
          totalCount={cartTotalCount}
          headerClass={classes.headerPadding}
        >
          {filteredCart &&
            filteredCart.length &&
            filteredCart.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || i}
                className={classNames('rowList', classes.table)}
                onClick={this.handleOpenEndUser(item)}
              >
                {this.headers.map((header: Header) => {
                  switch (header.key) {
                    case 'gender':
                      return (
                        <TableCell key={header.key} className={classes.tableCell}>
                          {item[header.key] === 'FEMALE' ? (
                            <img className={classes.genderColor} src={Female} alt="female" />
                          ) : (
                            <img className={classes.genderColor} src={Male} alt="male" />
                          )}
                        </TableCell>
                      );
                    case 'project':
                    case 'group':
                      return (
                        <TableCell key={header.key} className={classes.tableCell}>
                          {item[header.key].name}
                        </TableCell>
                      );
                    case 'location':
                      return (
                        <TableCell key={header.key} className={classNames(classes.tableCell, classes.location)}>
                          {item[header.key].name}
                        </TableCell>
                      );
                    case 'createdDate':
                      return (
                        <TableCell key={header.key} className={classes.tableCell}>
                          {item && item.measurement[header.key] ? item.measurement[header.key] : '-'}
                        </TableCell>
                      );
                    case 'createdBy':
                      return (
                        <TableCell key={header.key} className={classes.tableCell}>
                          {item.measurement && item.measurement.createdBy.username}
                        </TableCell>
                      );
                    case 'action':
                      return (
                        <TableCell key={header.key} className={classNames(classes.tableCell, classes.orderCell)}>
                          {cart && cart.findIndex(endUser => endUser.id === item.id) !== -1 && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={e => {
                                this.removeFromCart(e, item);
                              }}
                              className={classNames(classes.button, classes.cartBtn)}
                            >
                              {t('END_USER_LIST.REMOVE_CART')}
                            </Button>
                          )}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell key={header.key} className={classes.tableCell}>
                          {item[header.key]}
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
        </List>
        <Fab
          color="primary"
          aria-label="Add"
          className={classNames(classes.fab, classes.addButton)}
          onClick={this.submit}
        >
          {t('CART.CREATE_ORDER')}
        </Fab>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: '100%',
      position: 'relative',
      height: 'calc(100vh - 90px)'
    },

    rowList: {
      cursor: 'pointer'
    },

    addButton: {
      color: '#fff',
      position: 'fixed',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      width: 100,
      textTransform: 'none',
      borderRadius: 5,
      right: 40
    },
    genderColor: {
      color: COLORS.WHITE
    },

    location: {
      minWidth: 250,
      maxWidth: 315
    },

    createdBy: {
      minWidth: 250,
      maxWidth: 315
    },

    orderCell: {
      minWidth: 130,
      maxWidth: 130
    },

    cartBtn: {
      minWidth: 130,
      margin: '5px 0px'
    },

    actionBtn: {
      minWidth: 130
    },

    headerPadding: {
      padding: '10px 20px 10px 10px;',
      textAlign: 'center'
    },

    table: {
      padding: '10px 40px 10px 10px'
    }
  });

export default withStyles(styles)(withTranslation()(EndUserList));
