import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../styles/colors';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { RouteComponentProps } from 'react-router';
import List, { Header } from '../List';
import EndUserStore, { EndUser } from '../../stores/endUserStore';
import { inject, observer } from 'mobx-react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Filters from '../Filters';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Female from '../../assets/sex/gender-female.svg';
import Male from '../../assets/sex/gender-male.svg';
import ProjectStore from '../../stores/projectStore';
import GroupStore from '../../stores/groupStore';
import LocationStore from '../../stores/locationStore';
import AuthStore from '../../stores/authStore';
import Button from '@material-ui/core/Button';
import CartStore from '../../stores/cartStore';
import Tooltip from '@material-ui/core/Tooltip';
import Cart from '@material-ui/icons/ShoppingCart';
import AppStore from '../../stores/appStore';

interface MatchParams {}

interface EndUserListProps extends WithTranslation {}

interface InjectedProps extends EndUserListProps, RouteComponentProps<MatchParams> {
  endUserStore: EndUserStore;
  authStore: AuthStore;
  appStore: AppStore;
  projectStore: ProjectStore;
  groupStore: GroupStore;
  locationStore: LocationStore;
  cartStore: CartStore;
  classes: any;
}

interface EndUserListState {
  filters: {
    [key: string]: string;
  };
  sortParams: {
    [key: string]: string;
  };
  groups: { label: string; value: any }[];
  locations: { label: string; value: any }[];
  cartEndUser: string[];
}

@inject('endUserStore', 'groupStore', 'locationStore', 'projectStore', 'authStore', 'cartStore', 'appStore')
@observer
class EndUserList extends Component<EndUserListProps, EndUserListState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    filters: {},
    sortParams: {'sort':'measurement.createdDate','order':'desc'},
    groups: [],
    locations: [],
    cartEndUser: []
  };

  componentDidMount(): void {
    this.getProject(this.p.authStore.activeProject);
  }

  getProject = async (id: string) => {
    await this.p.projectStore.getProjectByIdAction(id);
  };

  getGroupsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    if (project) {
      this.setState({
        groups: project.groups
          .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
          .map((obj: any) => ({
            label: obj.name,
            value: obj.id
          }))
      });
    }
  };

  getLocationsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    if (project) {
      this.setState({
        locations: project.locations
          .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
          .map((obj: any) => ({
            label: obj.name,
            value: obj.id
          }))
      });
    }
  };

  addToCart = (e: any, endUser: any) => {
    e.stopPropagation();
    if (this.p.cartStore.cart.findIndex(item => item.id === endUser.id) === -1) {
      this.p.cartStore.addEndUserToCartAction(endUser);
    }
  };

  removeFromCart = (e: any, endUser: any) => {
    e.stopPropagation();
    if (this.p.cartStore.cart.findIndex(item => item.id === endUser.id) !== -1) {
      this.p.cartStore.removeEndUserFromCartAction(endUser);
    }
  };

  headers = [
    {
      name: this.p.t('END_USER_LIST.ACTION'),
      key: 'action'
    },
    {
      name: this.p.t('END_USER_LIST.NAME'),
      key: 'firstName'
    },
    {
      name: this.p.t('END_USER_LIST.SURNAME'),
      key: 'lastName',
      sort: true
    },
    {
      name: this.p.t('END_USER_LIST.PID'),
      key: 'pid'
    },
    {
      name: this.p.t('END_USER_LIST.GROUP'),
      key: 'group'
    },
    {
      name: this.p.t('END_USER_LIST.LOCATION'),
      key: 'location'
    },
    {
      name: this.p.t('END_USER_LIST.GENDER'),
      key: 'gender'
    },

    {
      name: this.p.t('END_USER_LIST.CREATED_DATE'),
      key: 'measurement.createdDate',
      sort: true
    },

    {
      name: this.p.t('END_USER_LIST.CREATED_BY'),
      key: 'createdBy'
    },

    {
      name: this.p.t('END_USER_LIST.ORDER_NUMBER'),
      key: 'externalId'
    },

    {
      name: this.p.t('END_USER_LIST.ORDER_STATUS'),
      key: 'status'
    },

    {
      name: this.p.t('END_USER_LIST.INVOICE_DATE'),
      key: 'invoiceDate'
    },

    {
      name: this.p.t('END_USER_LIST.CHOSEN_LABEL'),
      key: 'chosenLabel'
    },

    {
      name: this.p.t('END_USER_LIST.BREASTS_LABEL'),
      key: 'breastsLabel'
    },

    {
      name: this.p.t('END_USER_LIST.V'),
      key: 'v'
    },

    {
      name: this.p.t('END_USER_LIST.CHEST_HEIGHT'),
      key: 'chestHeight'
    },

    {
      name: this.p.t('END_USER_LIST.BREASTS_UNDER'),
      key: 'breastsUnder'
    },

    {
      name: this.p.t('END_USER_LIST.WAIST'),
      key: 'waist'
    },

    {
      name: this.p.t('END_USER_LIST.HEIGHT'),
      key: 'height'
    },

    {
      name: this.p.t('END_USER_LIST.CHEST_WIDTH'),
      key: 'chestWidth'
    },

    {
      name: this.p.t('END_USER_LIST.BACK_CHOSEN_LABEL'),
      key: 'backChosenLabel'
    },

    {
      name: this.p.t('END_USER_LIST.COMMENT'),
      key: 'remark'
    }
  ];

  getEndUserList = (sortParams: any) => {
    if (Object.keys(sortParams).length) {
      this.setState({ ...sortParams });
    }
    const sort = { ...this.state.sortParams, ...sortParams };
    const params = {
      ...sort,
      ...this.state.filters
    };

    if (params.active === 'all') {
      const { active, ...data } = params;
      this.p.endUserStore.getEndUserListAction(data);
    } else {
      this.p.endUserStore.getEndUserListAction(params);
    }
  };

  handleAddEndUser = () => {
    this.p.history.push(`/end-users/add`);
  };

  handleOpenEndUser = (endUser: EndUser) => () => {
    this.p.history.push(`/end-users/details/${endUser.id}`);
  };

  handleChange = (filters: any) => {
    this.setState({ filters }, () => this.getEndUserList({ page: 0 }));
  };

  render() {
    const filters = [
      {
        name: 'lastName',
        label: this.p.t('END_USER_LIST.SURNAME'),
        type: 'input'
      },
      {
        name: 'pid',
        label: this.p.t('END_USER_LIST.PID'),
        type: 'input'
      },
      {
        name: 'createdBy',
        label: this.p.t('END_USER_LIST.CREATED_BY'),
        type: 'input'
      },
      {
        name: 'orderNumber',
        label: this.p.t('END_USER_LIST.ORDER_NUMBER'),
        type: 'input'
      },
      {
        name: 'group',
        label: this.p.t('END_USER_LIST.GROUP'),
        type: 'autocomplete',
        getDataFunction: this.getGroupsOptions,
        options: this.state.groups
      },
      {
        name: 'location',
        label: this.p.t('END_USER_LIST.LOCATION'),
        type: 'autocomplete',
        getDataFunction: this.getLocationsOptions,
        options: this.state.locations
      },
      {
        name: 'dateFrom',
        label: this.p.t('END_USER_LIST.DATE_FROM'),
        type: 'datepicker'
      },
      {
        name: 'dateTo',
        label: this.p.t('END_USER_LIST.DATE_TO'),
        type: 'datepicker'
      }
    ];
    const {
      classes,
      t,
      endUserStore: { endUserList, endUsersTotalCount },
      appStore: { isFilter },
      cartStore: { cart }
    } = this.p;
    return (
      <div className={classes.listContainer}>
        <Typography className={classNames(classes.dividerFullWidth, 'title')} display="block" variant="subtitle1">
          {t('END_USER_LIST.TITLE')}
        </Typography>
        <Divider component="div" className={'divider'} />
        <Filters handleFilter={this.handleChange} filters={filters} />
        <List
          data={endUserList}
          getData={this.getEndUserList}
          headers={this.headers}
          isFilter={isFilter}
          totalCount={endUsersTotalCount}
        >
          {endUserList &&
            endUserList.length &&
            endUserList.map((item: any, i: number) => (
              <TableRow
                key={item.uuid || item.id || 'row-' + i}
                className={'rowList'}
                onClick={this.handleOpenEndUser(item)}
              >
                {this.headers.map((header: Header, index: number) => {
                  switch (header.key) {
                    case 'gender':
                      return (
                        <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                          {item[header.key] === 'FEMALE' ? (
                            <img className={classes.genderColor} src={Female} alt="female" />
                          ) : (
                            <img className={classes.genderColor} src={Male} alt="male" />
                          )}
                        </TableCell>
                      );
                    case 'project':
                    case 'group':
                      return (
                        <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                          {item[header.key].name}
                        </TableCell>
                      );
                    case 'location':
                      return (
                        <Tooltip
                          key={`row-${i}-col-${index}`}
                          title={
                            item[header.key].name.length > 20 ? (
                              <React.Fragment>
                                <Typography color="inherit">{item[header.key].name}</Typography>
                              </React.Fragment>
                            ) : (
                              ''
                            )
                          }
                        >
                          <TableCell
                            key={`row-${i}-col-${index}`}
                            className={classNames(classes.tableCell, classes.location)}
                          >
                            {item && item[header.key].name ? item[header.key].name.slice(0, 20) : '-'}
                            {item && item[header.key].name && item[header.key].name.length > 20 ? '...' : ''}
                          </TableCell>
                        </Tooltip>
                      );
                    case 'chestHeight':
                    case 'chestWidth':
                    case 'chosenId':
                    case 'chosenLabel':
                    case 'height':
                    case 'suggestedLabel':
                    case 'waist':
                    case 'backChosenId':
                    case 'backChosenLabel':
                    case 'breastsLabel':
                    case 'breastsUnder':
                    case 'suggestedBreastsLabel':
                      return (
                        <TableCell
                          key={`row-${i}-col-${index}`}
                          className={classNames(classes.tableCell, classes.measurement)}
                        >
                          {item && item.measurement[header.key] ? item.measurement[header.key] : '-'}
                        </TableCell>
                      );
                    case 'remark':
                      return (
                        <Tooltip
                          key={`row-${i}-col-${index}`}
                          title={
                            item.measurement[header.key] ? (
                              <React.Fragment>
                                <Typography color="inherit">{item.measurement[header.key]}</Typography>
                              </React.Fragment>
                            ) : (
                              ''
                            )
                          }
                        >
                          <TableCell
                            key={`row-${i}-col-${index}`}
                            className={classNames(classes.tableCell, classes.remark)}
                          >
                            {item && item.measurement[header.key] ? item.measurement[header.key].slice(0, 40) : '-'}
                            {item && item.measurement[header.key] && item.measurement[header.key].length > 40
                              ? '...'
                              : ''}
                          </TableCell>
                        </Tooltip>
                      );
                    case 'v':
                      return (
                        <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                          {(item && item.measurement[header.key]) || item.measurement[header.key] === 0
                            ? item.measurement[header.key]
                            : '-'}
                        </TableCell>
                      );
                    case 'measurement.createdDate':
                      return (
                        <TableCell
                          key={`row-${i}-col-${index}`}
                          className={classNames(classes.tableCell, classes.createdDate)}
                        >
                          {item && item.measurement['createdDate'] ? item.measurement['createdDate'] : '-'}
                        </TableCell>
                      );
                    case 'createdBy':
                      return (
                        <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                          {item.measurement && item.measurement.createdBy.username}
                        </TableCell>
                      );
                    case 'action':
                      return (
                        <TableCell
                          key={`row-${i}-col-${index}`}
                          className={classNames(classes.tableCell, classes.orderCell)}
                        >
                          {cart && cart.findIndex(endUser => endUser.id === item.id) !== -1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={e => {
                                this.removeFromCart(e, item);
                              }}
                              className={classNames(classes.button, classes.cartBtn)}
                            >
                              {t('END_USER_LIST.REMOVE_CART')}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={e => {
                                this.addToCart(e, item);
                              }}
                              className={classNames(classes.button, classes.cartBtn)}
                              disabled={!item.measurement.createdDate || !item.orderable}
                            >
                              {<Cart />}
                            </Button>
                          )}
                        </TableCell>
                      );
                    case 'firstName':
                    case 'lastName':
                      return (
                        <TableCell
                          key={`row-${i}-col-${index}`}
                          className={classNames(classes.tableCell, classes.nameCell, 'capitalizeText')}
                        >
                          {item[header.key]}
                        </TableCell>
                      );
                    case 'externalId':
                    case 'status':
                    case 'invoiceDate':
                      return (
                        <TableCell
                          key={`row-${i}-col-${index}`}
                          className={classNames(
                            classes.tableCell,
                            header.key === 'invoiceDate' ? classes.invoiceDate : classes.status
                          )}
                        >
                          {item.order[header.key]}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                          {item[header.key]}
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
        </List>
        <Fab
          color="primary"
          aria-label="Add"
          className={classNames(classes.fab, classes.addButton)}
          onClick={this.handleAddEndUser}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: '100%',
      position: 'relative',
      height: 'calc(100vh - 90px)'
    },

    rowList: {
      cursor: 'pointer'
    },

    addButton: {
      color: '#fff',
      position: 'fixed',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: 40
    },
    genderColor: {
      color: COLORS.WHITE
    },

    location: {
      whiteSpace: 'nowrap'
    },

    createdDate: {
      minWidth: 130,
      maxWidth: 200
    },

    createdBy: {
      minWidth: 250,
      maxWidth: 315
    },

    orderCell: {
      minWidth: 130,
      maxWidth: 130
    },

    cartBtn: {
      minWidth: 130,
      margin: '5px 0px',
      zIndex: 10
    },

    actionBtn: {
      minWidth: 130
    },

    nameCell: {
      minWidth: 130,
      maxWidth: 200
    },

    invoiceDate: {
      minWidth: 140,
      maxWidth: 200
    },

    status: {
      minWidth: 100,
      maxWidth: 200
    },

    remark: {
      whiteSpace: 'nowrap'
    },

    gender: {
      minWidth: 50,
      maxWidth: 70
    },

    measurement: {
      minWidth: 80,
      maxWidth: 120
    },

    tooltipFontSize: {
      fontSize: 20
    }
  });

export default withStyles(styles)(withTranslation()(EndUserList));
