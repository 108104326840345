import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import createStyles from '@material-ui/core/styles/createStyles';
import { RouteComponentProps } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';

interface MatchParams {}

interface MatrixProps extends WithTranslation {
  value: string;
  recommended: string;
  onChange: (item: string) => void;
}

interface InjectedProps extends MatrixProps, RouteComponentProps<MatchParams> {
  classes: any;
}

interface MatrixState {}

class Matrix extends Component<MatrixProps, MatrixState> {
  get p() {
    return this.props as InjectedProps;
  }
  componentDidMount(): void {
    this.setActive(this.p.recommended)();
  }

  getPossibilitiesTable = () => {
    const { recommended } = this.p;
    const size = recommended.substring(0, recommended.length - 1);
    const number = recommended.substring(recommended.length - 1, recommended.length);
    if (parseInt(number) === 1) {
      return [`${size}${parseInt(number)}`, `${size}${parseInt(number) + 1}`];
    } else if (parseInt(number) === 9) {
      return [`${size}${parseInt(number) - 1}`, `${size}${parseInt(number)}`];
    } else {
      return [`${size}${parseInt(number) - 1}`, `${size}${parseInt(number)}`, `${size}${parseInt(number) + 1}`];
    }
  };

  setActive = (item: string) => () => {
    this.p.onChange(item);
  };

  render() {
    const { classes, value } = this.p;
    return (
      <div className={classes.matrixContainer}>
        <div className={classes.matrix}>
          {this.getPossibilitiesTable().map((item: string) => (
            <div
              key={item}
              onClick={this.setActive(item)}
              className={classNames({
                [classes.cell]: true,
                [classes.selected]: item === value
              })}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    matrixContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    label: {
      marginBottom: 10
    },
    matrix: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 100,
      minWidth: 60,
      marginTop: 10
    },
    cell: {
      flex: '1 1 calc(14%)',
      padding: '16px 26px',
      textAlign: 'center',
      fontWeight: 700,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      cursor: 'pointer',
      width: 40,
      userSelect: 'none',
      '@media (max-width:1130px)': {
        flex: '1 1 calc(16%)',
        padding: '6px 3px'
      }
    },
    selected: {
      background: 'rgba(255, 255, 255, 0.3)'
    }
  });

export default withStyles(styles)(withTranslation()(Matrix));
