import REST from '../constants/rest';
import API from './apiService';

export default class GroupService {
  static async getGroups(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/groups${params ? '?' + urlSP.toString() : ''}&include=locations`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getGroupById(id: string) {
    const path = `/groups/${id}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addGroup(data: any) {
    const path = '/groups';
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editGroup(params: any, id: string) {
    const path = `/groups/${id}`;
    const body = {
      name: params.name
    };
    const options = {
      method: REST.POST,
      body
    };
    return API.fetch(path, options);
  }

  static async deleteGroup(id: string) {
    const path = `/groups/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }
}
