import { action, configure, observable } from 'mobx';
import groupService from '../services/groupService';

configure({ enforceActions: 'observed' });

interface GroupBase {
  name: string;
  active: boolean;
}

interface GroupOption {
  label: string;
  value: string;
}

export interface Group extends GroupBase {
  id: string;
  locations: Location[];
}

export interface GroupLI extends GroupBase {
  id: string;
  locations: string[];
}

export default class GroupStore {
  // STORE
  @observable group: Group = {
    id: '',
    name: '',
    active: true,
    locations: []
  };

  @observable pending: boolean = false;

  @observable groupList: GroupLI[] = [];
  @observable groupOptionsAutocomplete: GroupOption[] = [];
  @observable locationGroup: any = [];
  @observable locationList: any = [];
  @observable locationTab: any = [];
  @observable groupsTotalCount?: number;

  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.groupsTotalCount = totalCount;
  };

  // ACTIONS

  @action
  private setGroup = (group: Group) => {
    this.group = group;
  };

  @action
  private setGroupList = (groupList: GroupLI[]) => {
    this.groupList = groupList;
  };

  @action
  private setGroupOptions = (groupList: GroupLI[]) => {
    this.groupOptionsAutocomplete = groupList.map((item: GroupLI) => ({
      label: item.name,
      value: item.id
    }));
  };

  @action
  getGroupListAction = async (params?: any) => {
    this.pendingStart();
    const result = await groupService.getGroups(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setGroupList(result.groups);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getGroupOptionsAutocompleteAction = async (params?: any) => {
    this.pendingStart();
    const result = await groupService.getGroups(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }

    this.setGroupOptions(
      result.groups.map((item: Group) => ({
        label: item.name,
        value: item.id
      }))
    );
    return true;
  };

  @action
  setLocationGroup = (params: any) => {
    this.locationGroup = params;
    this.getGroupByIdAction(params);
  };

  @action
  getListGroupByIdAction = async (id: string) => {
    this.pendingStart();
    this.locationTab = [];
    this.setLocation(this.locationTab);

    const result = await groupService.getGroupById(id);
    result.group &&
      result.group.locations.forEach((item: any) => {
        if (!this.locationTab.find((location: any) => location.value === item.id)) {
          this.locationTab.push({ label: item.name, value: item.id, groupName: result.group.name });
        }
      });
    this.setLocation(this.locationTab);
  };

  @action
  private setLocation = (locationList: Location[]) => {
    this.locationList = locationList;
  };

  @action
  addGroupAction = async (data: any) => {
    this.pendingStart();
    const result = await groupService.addGroup(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getGroupListAction();
    return true;
  };

  @action
  getGroupByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await groupService.getGroupById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setGroup(result.group);
    return true;
  };

  @action
  editGroupAction = async (params: GroupBase, id: string) => {
    this.pendingStart();
    const result = await groupService.editGroup({ ...params }, id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  deleteGroupAction = async (id: string) => {
    this.pendingStart();
    const result = await groupService.deleteGroup(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
}
