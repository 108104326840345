import REST from '../constants/rest';
import API from './apiService';

export default class EndUserService {
  static async getEndUsers(params?: any) {
    const urlSP = new URLSearchParams(params);
    const path = `/persons?include=group,project,location,creator${params ? '&' + urlSP.toString() : ''}`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async getEndUserById(id: string) {
    const path = `/persons/${id}?include=group,project,location`;
    const options = {
      method: REST.GET
    };
    return API.fetch(path, options);
  }

  static async addEndUser(data: any) {
    const path = '/persons';
    const options = {
      method: REST.POST,
      body: data
    };
    return API.fetch(path, options);
  }

  static async editEndUser(params: any, id: string) {
    const path = `/persons/${id}`;
    const options = {
      method: REST.POST,
      body: params
    };
    return API.fetch(path, options);
  }

  static async deleteEndUser(id: string) {
    const path = `/persons/${id}`;
    const options = {
      method: REST.DELETE
    };
    return API.fetch(path, options);
  }
}
