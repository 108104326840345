import React, { Component } from 'react';
import { FormControlLabel, Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import { Field, Form, Formik, FormikActions } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import * as Yup from 'yup';
import { StringValidator } from '../../../utils/validators';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../../styles/colors';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import Matrix from '../Matrix';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import MatrixBack from '../MatrixBack';
import MeasurementStore from '../../../stores/measurementStore';
import AccountStore from '../../../stores/accountStore';
import Check from '@material-ui/icons/RadioButtonChecked';
import SkipMeasurement, { SizesStepForm } from './SkipMeasurement';
import InfoIcon from '@material-ui/icons/Info';
import FemalePanel from '../../../assets/breast/female_panel_side_dark.png';
// @ts-ignore
import Rodal from 'rodal';

interface MatchParams {
  id: string;
}

interface SizeSelectionStepProps extends WithTranslation, InjectedNotistackProps {
  handleNext: (event: any) => void;
  handleBack: () => void;
  handleNextAfterSkip: (event: any) => void;
  handleBackAfterSkip: () => void;
  handleCancel: () => void;
  gender: string;
  values: SizeSelectionStepForm;
  valuesSkip: SizesStepForm;
  skipped: boolean;
}

interface InjectedProps extends SizeSelectionStepProps, RouteComponentProps<MatchParams> {
  measurementStore: MeasurementStore;
  accountStore: AccountStore;
  classes: any;
}

interface SizeSelectionStepState {
  edit: boolean;
  backSize: boolean;
  sizeStep: SizesStepForm;
  open: boolean;
  visible: number;
}

const Breast = [FemalePanel];

export class SizeSelectionStepForm {
  constructor(values?: any) {
    if (values) {
      this.breastSize = values.breastSize || '';
      this.selectedSize = values.selectedSize || '';
      this.selectedBackSize = values.selectedBackSize || '';
      this.remark = values.remark || '';
    }
  }

  breastSize: string = '';
  selectedSize: string = '';
  selectedBackSize: string = '';
  remark: string = '';
}

const SizeSelectionStepSchema = Yup.object().shape<SizeSelectionStepForm>({
  breastSize: Yup.string(),
  selectedSize: StringValidator,
  selectedBackSize: Yup.string(),
  remark: Yup.string()
});

@inject('measurementStore', 'accountStore')
@observer
class SizeSelectionStep extends Component<SizeSelectionStepProps, SizeSelectionStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    edit: false,
    backSize: false,
    open: false,
    visible: 0,
    sizeStep: new SizesStepForm('')
  };

  componentDidMount(): void {
    this.p.accountStore.getAccountAction();
  }

  submit = async (values: SizeSelectionStepForm) => {
    this.p.handleNext(values);
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  getBreast = () => {
    return Breast[0];
  };

  show = (index: number) => {
    this.setState({ visible: index });
  };

  hide = () => {
    this.setState({ visible: 0 });
  };

  handleBackCheckbox = () => {
    this.setState((prevState: SizeSelectionStepState) => ({
      backSize: !prevState.backSize
    }));
  };

  render() {
    const {
      classes,
      t,
      values,
      measurementStore: { suggestion }
    } = this.p;
    const isIE = window.navigator.userAgent.indexOf('Trident') !== -1;
    const { backSize } = this.state;
    return (
      <>
        {this.p.skipped && (
          <SkipMeasurement
            values={this.p.valuesSkip}
            handleCancel={this.p.handleCancel}
            gender={this.p.gender}
            backSize={this.state.backSize}
            handleBackCheckbox={this.handleBackCheckbox}
            handleBackAfterSkip={this.p.handleBackAfterSkip}
            handleNextAfterSkip={(values: any) => {
              this.p.handleNextAfterSkip(values);
              this.setState({ sizeStep: values });
            }}
            skipped={this.p.skipped}
          />
        )}
        <div className={classes.stepContainer}>
          {suggestion && !this.p.skipped && suggestion.recommended.length && (
            <Formik
              enableReinitialize
              initialValues={
                new SizeSelectionStepForm(
                  values.selectedSize
                    ? values
                    : {
                        breastSize: suggestion.breastRecommended,
                        selectedSize: suggestion.recommended
                      }
                )
              }
              validationSchema={SizeSelectionStepSchema}
              onSubmit={(values: SizeSelectionStepForm, actions: FormikActions<SizeSelectionStepForm>) => {
                this.submit(values).then(() => {
                  actions.setSubmitting(false);
                });
              }}
              onReset={values => {
                values = new SizeSelectionStepForm();
              }}
            >
              {props => {
                const { isSubmitting, setFieldValue, setFieldTouched, values } = props;
                return (
                  <Form noValidate className={classes.formWidth}>
                    <div className={classes.suggestForm}>
                      <div className={classes.sizeSelectionStep}>
                        <div className={isIE ? classes.matrix : ''}>
                          <Matrix
                            recommended={suggestion.recommended}
                            suggestions={suggestion.suggestions}
                            matrix={suggestion.matrix}
                            onChange={(selected: string) => {
                              setFieldValue('selectedSize', selected);
                              setFieldValue('selectedBackSize', selected);
                              setFieldTouched('selectedSize');
                            }}
                            value={values.selectedSize}
                          />
                        </div>
                        {this.p.accountStore.account.extendedMeasurement && (
                          <div className={isIE ? classes.backSizeSelect : ''}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={backSize}
                                  className={classes.backSize}
                                  onChange={this.handleBackCheckbox}
                                  value="back"
                                  color="primary"
                                />
                              }
                              label={t('MEASUREMENT.SELECT_BACK_SIZE')}
                            />
                            {backSize && (
                              <MatrixBack
                                recommended={values.selectedSize}
                                value={values.selectedBackSize}
                                onChange={(selected: string) => {
                                  setFieldValue('selectedBackSize', selected);
                                  setFieldTouched('selectedBackSize');
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className={classes.breast}>
                        {this.p.gender === 'FEMALE' && (
                          <div className={classes.breastSize}>
                            <InputLabel className={classes.label}>{t('MEASUREMENT.BREAST_SIZE')}</InputLabel>
                            <Field name="breastSize" className={classes.radioRow} component={RadioGroup}>
                              <div className={classes.breastContainer}>
                                <FormControlLabel
                                  value="A-B"
                                  control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                                  label="A-B"
                                  disabled={isSubmitting}
                                />
                                <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(1)} />
                              </div>
                              <div className={classes.breastContainer}>
                                <FormControlLabel
                                  value="C-D"
                                  control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                                  label="C-D"
                                  disabled={isSubmitting}
                                />
                                <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(2)} />
                              </div>
                              <div className={classes.breastContainer}>
                                <FormControlLabel
                                  value="E+"
                                  control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                                  label="E+"
                                  disabled={isSubmitting}
                                />
                                <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(3)} />
                              </div>
                              <Rodal
                                visible={this.state.visible}
                                onClose={this.hide}
                                width={500}
                                height={400}
                                customStyles={{
                                  backgroundColor: COLORS.DIALOG_COLOR,
                                  color: '#ffffff'
                                }}
                              >
                                <div>
                                  <img className={classes.breastPopUp} src={this.getBreast()} alt="Breast version" />
                                </div>
                              </Rodal>
                            </Field>
                          </div>
                        )}
                      </div>
                      <div className={classes.remark}>
                        <Field
                          id="remark"
                          name="remark"
                          label={t('MEASUREMENT.REMARK')}
                          required
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                              root: classes.formControl
                            },
                            multiline: true
                          }}
                          component={TextField}
                          className={classes.textField}
                          margin="normal"
                          autoComplete="off"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={classes.btnContainer}>
                      <div className={classes.leftButton}>
                        <Button onClick={this.p.handleCancel} className={classes.button}>
                          {t('CANCEL')}
                        </Button>
                      </div>
                      <div className={classes.rightButton}>
                        <Button onClick={this.p.handleBack} className={classes.button}>
                          {t('BACK')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!values.selectedSize}
                          className={classes.buttonNext}
                        >
                          {t('NEXT')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: '#fff',
      position: 'absolute',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    textField: {
      width: '100%',
      maxWidth: 380
    },

    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 372px)',
      position: 'fixed',
      bottom: 40,
      '@media (max-width:1200px)': {
        padding: 20,
        position: 'relative',
        bottom: 0,
        width: '100%'
      }
    },

    sizeSelectionStep: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    },
    label: {
      marginBottom: 10
    },

    backSize: {
      padding: 0
    },

    suggestForm: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      '@media (max-width:1200px)': {
        flexDirection: 'column'
      }
    },

    breastSize: {
      textAlign: 'center'
    },

    radioRow: {
      flexDirection: 'column'
    },

    remark: {
      marginTop: 55,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: 'right',
      '@media (max-width:500px)': {
        display: 'flex',
        flexDirection: 'row'
      }
    },

    button: {
      width: '20%'
    },

    buttonNext: {
      marginLeft: 20,
      width: '20%',
      '@media (max-width:1200px)': {
        marginLeft: 10
      }
    },
    matrix: {
      height: 250
    },

    backSizeSelect: {
      height: 200,
      textAlign: 'center'
    },

    breast: {
      marginTop: 55,
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width:1200px)': {
        justifyContent: 'center'
      }
    },

    breastContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    test: {
      background: 'blue'
    },

    breastPopUp: {
      maxWidth: 500,
      width: '100%',
      height: 'auto'
    },

    formWidth: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(SizeSelectionStep)));
