import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Theme, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import { PhoneValidator, StringValidator } from '../../utils/validators';
import { withTranslation, WithTranslation } from 'react-i18next';
import AccountStore, { Account } from '../../stores/accountStore';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../styles/colors';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import { RouteComponentProps } from 'react-router';

interface MatchParams {}

interface ProfileProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends ProfileProps, RouteComponentProps<MatchParams> {
  accountStore: AccountStore;
  classes: any;
  userId: string;
}

export class ProfileForm {
  constructor(account?: Account) {
    if (account) {
      this.firstName = account.firstName;
      this.lastName = account.lastName;
      this.email = account.email;
      this.phone = account.phone;
      this.projects = account.projects;
      this.extendedMeasurement = account.extendedMeasurement.toString();
    }
  }

  firstName: string = '';
  lastName: string = '';
  email: string = '';
  extendedMeasurement: string = 'false';
  phone: string = '';
  projects: string[] = [];
}

const ProfileSchema = Yup.object().shape({
  firstName: StringValidator,
  lastName: StringValidator,
  email: StringValidator,
  phone: PhoneValidator
});

@inject('accountStore')
@observer
class ProfileEdit extends Component<ProfileProps> {
  get p() {
    return this.props as InjectedProps;
  }

  submit = async (values: ProfileForm) => {
    this.editProfile(values);
  };

  editProfile = async (values: ProfileForm) => {
    const success = await this.p.accountStore.updateAccountAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t('PROFILE.GLOBAL_ERROR'), {
        variant: 'error'
      });
    } else {
      this.p.enqueueSnackbar(this.p.t('PROFILE.EDIT_SUCCESS'), {
        variant: 'success'
      });
    }
  };

  render() {
    const {
      classes,
      t,
      accountStore: { account }
    } = this.p;
    return (
      <Formik
        enableReinitialize
        initialValues={new ProfileForm(account)}
        validationSchema={ProfileSchema}
        onSubmit={(values: ProfileForm, actions: FormikActions<ProfileForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new ProfileForm();
        }}
      >
        {props => {
          const { isSubmitting, isValid } = props;
          return (
            <Form noValidate className={classes.profileForm}>
              <div className={classes.title}>
                <Typography
                  className={classNames(classes.dividerFullWidth, classes.profileInfo)}
                  color="textSecondary"
                  display="block"
                  variant="subtitle1"
                >
                  {t('PROFILE.PERSONAL_DATA')}
                </Typography>
                <Divider component="div" className={classes.divider} />
              </div>
              <Field
                id="firstName"
                name="firstName"
                label={t('PROFILE.FIRST_NAME')}
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Field
                id="lastName"
                name="lastName"
                label={t('PROFILE.LAST_NAME')}
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Field
                id="email"
                name="email"
                label={t('PROFILE.EMAIL')}
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Field
                id="phone"
                name="phone"
                label={t('PROFILE.PHONE_NUMBER')}
                required
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.passwordBtn)}
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {t('PROFILE.UPDATE')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      maxWidth: 380,
      marginTop: 12
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    divider: {
      width: '100%',
      marginBottom: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },

    profileInfo: {
      alignSelf: 'flex-start',
      fontFamily: '"RbFontLight",serif !important'
    },

    passwordBtn: {
      marginTop: 20
    },

    profileForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    title: {
      textAlign: 'left',
      width: '30%',
      '@media (max-width:500px)': {
        width: '100%'
      }
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(ProfileEdit)));
