import { AddMeasurementState } from '../../components/measurements/MeasurementAdd';

export class SuggestionMale {
  constructor(values: any) {
    this.type = 'MALE_VEST';
    this.waist = values.waist * 100;
    this.height = values.bodyHeight * 100;
    this.chestWidth = values.chestCircum * 100;
    this.chestHeight = values.chestHeight * 100;
  }
  type: 'MALE_VEST';
  waist: number;
  height: number;
  chestWidth: number;
  chestHeight: number;
}

export class SuggestionFemale {
  constructor(values: any) {
    this.type = 'FEMALE_VEST';
    this.waist = values.waist * 100;
    this.height = values.bodyHeight * 100;
    this.chestWidth = values.bustCircum * 100;
    this.chestHeight = values.chestHeight * 100;
    this.breastUnder = values.bandCircum * 100;
  }
  type: 'FEMALE_VEST';
  waist: number;
  height: number;
  chestWidth: number;
  chestHeight: number;
  breastUnder: number;
}

export class MeasurementFormMale {
  constructor(values: AddMeasurementState) {
    this.type = 'MALE_VEST';
    this.chestWidth = parseFloat(values.measurementsStep.chestCircum) * 100;
    this.chestHeight = parseFloat(values.measurementsStep.chestHeight) * 100;
    this.waist = parseFloat(values.measurementsStep.waist) * 100;
    this.height = parseFloat(values.measurementsStep.bodyHeight) * 100;
    this.v = values.customizationStep.v;
    this.chosenLabel =
      values.sizeSelectionStep.selectedSize && values.sizeSelectionStep.selectedSize.length
        ? values.sizeSelectionStep.selectedSize
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber;
    this.backChosenLabel =
      values.sizeSelectionStep.selectedBackSize && values.sizeSelectionStep.selectedBackSize.length
        ? values.sizeSelectionStep.selectedBackSize
        : values.sizesStepForm.backSize
        ? values.sizesStepForm.sizing + values.sizesStepForm.backSize.toString()
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber.toString();
    this.remark = values.sizeSelectionStep.remark || values.sizesStepForm.remark;
  }

  type: 'MALE_VEST';
  waist: number;
  height: number;
  chestWidth: number;
  chestHeight: number;
  chosenLabel: string;
  backChosenLabel: string;
  v: number;
  remark?: string;
}

export class MeasurementFormFemale {
  constructor(values: AddMeasurementState) {
    this.type = 'FEMALE_VEST';
    this.chestWidth = parseFloat(values.measurementsStep.bustCircum) * 100;
    this.chestHeight = parseFloat(values.measurementsStep.chestHeight) * 100;
    this.waist = parseFloat(values.measurementsStep.waist) * 100;
    this.height = parseFloat(values.measurementsStep.bodyHeight) * 100;
    this.breastsUnder = parseFloat(values.measurementsStep.bandCircum) * 100;
    this.breastsLabel =
      values.sizeSelectionStep.breastSize && values.sizeSelectionStep.breastSize.length
        ? values.sizeSelectionStep.breastSize
        : values.sizesStepForm.breastSize;
    this.v = values.customizationStep.v;
    this.chosenLabel =
      values.sizeSelectionStep.selectedSize && values.sizeSelectionStep.selectedSize.length
        ? values.sizeSelectionStep.selectedSize
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber;
    this.backChosenLabel =
      values.sizeSelectionStep.selectedBackSize && values.sizeSelectionStep.selectedBackSize.length
        ? values.sizeSelectionStep.selectedBackSize
        : values.sizesStepForm.backSize
        ? values.sizesStepForm.sizing + values.sizesStepForm.backSize.toString()
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber.toString();
    this.remark = values.sizeSelectionStep.remark || values.sizesStepForm.remark;
  }

  type: 'FEMALE_VEST';
  waist: number;
  height: number;
  chestWidth: number;
  chestHeight: number;
  breastsUnder: number;
  breastsLabel: string;
  chosenLabel: string;
  backChosenLabel?: string;
  v: number;
  remark?: string;
}

export class MeasurementFormPartialMale {
  constructor(values: AddMeasurementState) {
    this.type = 'PARTIAL_MALE_VEST';
    this.v = values.customizationStep.v;
    this.chosenLabel =
      values.sizeSelectionStep.selectedSize && values.sizeSelectionStep.selectedSize.length
        ? values.sizeSelectionStep.selectedSize
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber;
    this.backChosenLabel =
      values.sizeSelectionStep.selectedBackSize && values.sizeSelectionStep.selectedBackSize.length
        ? values.sizeSelectionStep.selectedBackSize
        : values.sizesStepForm.backSize
        ? values.sizesStepForm.sizing + values.sizesStepForm.backSize.toString()
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber.toString();
    this.remark = values.sizeSelectionStep.remark || values.sizesStepForm.remark;
  }

  type: 'PARTIAL_MALE_VEST';
  chosenLabel: string;
  backChosenLabel: string;
  v: number;
  remark?: string;
}

export class MeasurementFormPartialFemale {
  constructor(values: AddMeasurementState) {
    this.type = 'PARTIAL_FEMALE_VEST';
    this.v = values.customizationStep.v;
    this.breastsLabel =
      values.sizeSelectionStep.breastSize && values.sizeSelectionStep.breastSize.length
        ? values.sizeSelectionStep.breastSize
        : values.sizesStepForm.breastSize;
    this.chosenLabel =
      values.sizeSelectionStep.selectedSize && values.sizeSelectionStep.selectedSize.length
        ? values.sizeSelectionStep.selectedSize
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber;
    this.backChosenLabel =
      values.sizeSelectionStep.selectedBackSize && values.sizeSelectionStep.selectedBackSize.length
        ? values.sizeSelectionStep.selectedBackSize
        : values.sizesStepForm.backSize
        ? values.sizesStepForm.sizing + values.sizesStepForm.backSize.toString()
        : values.sizesStepForm.sizing + values.sizesStepForm.sizeNumber.toString();
    this.remark = values.sizeSelectionStep.remark || values.sizesStepForm.remark;
  }

  type: 'PARTIAL_FEMALE_VEST';
  chosenLabel: string;
  backChosenLabel: string;
  breastsLabel: string;
  v: number;
  remark?: string;
}
