import * as React from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import BandCircum from '../../../assets/avatar/bandCircum.png';
import BustCircum from '../../../assets/avatar/bustCircum.png';
import BodyHeightFemale from '../../../assets/avatar/bodyHeightFemale.png';
import BodyHeightMale from '../../../assets/avatar/bodyHeightMale.png';
import ChestCircum from '../../../assets/avatar/chestCircum.png';
import ChestHeightMale from '../../../assets/avatar/chestHeightMale.png';
import ChestHeightFemale from '../../../assets/avatar/chestHeightFemale.png';
import WaistFemale from '../../../assets/avatar/waistFemale.png';
import WaistMale from '../../../assets/avatar/waistMale.png';
import Typography from '@material-ui/core/Typography';
import { Component } from 'react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { MeasurementValidator } from '../../../utils/validators';
import { inject, observer } from 'mobx-react';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../../styles/colors';
import Button from '@material-ui/core/Button';
import MeasurementStore from '../../../stores/measurementStore';
import InputAdornment from '@material-ui/core/InputAdornment';

interface MatchParams {
  id: string;
}

interface MeasurementsStepProps extends WithTranslation, InjectedNotistackProps {
  handleNext: (event: any) => void;
  handleBack: () => void;
  handleCancel: () => void;
  gender: string;
  values: MeasurementsStepForm;
}

interface InjectedProps extends MeasurementsStepProps, RouteComponentProps<MatchParams> {
  measurementStore: MeasurementStore;
  classes: any;
}

interface MeasurementsStepState {
  edit: boolean;
  activeAvatar: string;
  openClearDialog: string;
  open: boolean;
}

interface Avatars {
  [key: string]: {
    [key: string]: string;
  };
}

export class MeasurementsStepForm {
  constructor(gender: string, values?: any) {
    if (values) {
      this.chestCircum = values.chestCircum;
      this.bustCircum = values.bustCircum;
      this.bandCircum = values.bandCircum;
      this.chestHeight = values.chestHeight;
      this.waist = values.waist;
      this.bodyHeight = values.bodyHeight;
      this.gender = gender;
    }
  }

  chestCircum: any = '';
  bustCircum: any = '';
  bandCircum: any = '';
  chestHeight: any = '';
  waist: any = '';
  bodyHeight: any = '';
  gender: string = '';
}

const MeasurementsStepSchema = Yup.object().shape<MeasurementsStepForm>({
  gender: Yup.string(),
  chestCircum: Yup.number().when('gender', {
    is: val => val === 'MALE',
    then: MeasurementValidator(60, 160)
  }),
  bustCircum: Yup.number().when('gender', {
    is: val => val === 'FEMALE',
    then: MeasurementValidator(65, 140)
  }),
  bandCircum: Yup.number().when('gender', {
    is: val => val === 'FEMALE',
    then: MeasurementValidator(55, 135)
  }),

  chestHeight: Yup.number().when('gender', {
    is: val => val === 'MALE',
    then: MeasurementValidator(24, 54),
    otherwise: MeasurementValidator(25, 52)
  }),

  waist: Yup.number().when('gender', {
    is: val => val === 'MALE',
    then: MeasurementValidator(70, 145),
    otherwise: MeasurementValidator(54, 144)
  }),

  bodyHeight: MeasurementValidator(140, 220)
});

@inject('measurementStore')
@observer
class MeasurementsStep extends Component<MeasurementsStepProps, MeasurementsStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  band = null;
  chest = null;
  waist = null;
  chestHeight = null;
  bodyHeight = null;

  state = {
    edit: false,
    activeAvatar: '',
    openClearDialog: '',
    open: false
  };

  submit = async (values: MeasurementsStepForm) => {
    this.p.measurementStore.getSuggestionsAction(values);
    this.p.handleNext(values);
  };

  handleImage = (event: any) => {
    this.setState({ activeAvatar: event.target.name + this.p.gender });
  };

  handleClose = () => {
    this.setState({ openClearDialog: '', open: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes, t, values } = this.p;
    const { activeAvatar } = this.state;
    const avatars: Avatars = {
      bandCircumFEMALE: {
        avatar: BandCircum,
        title: t('MEASUREMENT.SECOND_STEP.BAND_CIRCUM_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.BAND_CIRCUM_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.BAND_CIRCUM_MEASURED_PARAMETER')
      },
      bustCircumFEMALE: {
        avatar: BustCircum,
        title: t('MEASUREMENT.SECOND_STEP.BUST_CIRCUM_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.BUST_CIRCUM_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.BUST_CIRCUM_MEASURED_PARAMETER')
      },
      chestCircumMALE: {
        avatar: ChestCircum,
        title: t('MEASUREMENT.SECOND_STEP.CHEST_CIRCUM_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.CHEST_CIRCUM_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.CHEST_CIRCUM_MEASURED_PARAMETER')
      },
      bodyHeightMALE: {
        avatar: BodyHeightMale,
        title: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_MEASURED_PARAMETER')
      },
      bodyHeightFEMALE: {
        avatar: BodyHeightFemale,
        title: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.BODY_HEIGHT_MEASURED_PARAMETER')
      },
      chestHeightMALE: {
        avatar: ChestHeightMale,
        title: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_MEASURED_PARAMETER')
      },
      chestHeightFEMALE: {
        avatar: ChestHeightFemale,
        title: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.CHEST_HEIGHT_MEASURED_PARAMETER')
      },
      waistMALE: {
        avatar: WaistMale,
        title: t('MEASUREMENT.SECOND_STEP.WAIST_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.WAIST_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.WAIST_MEASURED_PARAMETER')
      },
      waistFEMALE: {
        avatar: WaistFemale,
        title: t('MEASUREMENT.SECOND_STEP.WAIST_TITLE'),
        measuringPosition: t('MEASUREMENT.SECOND_STEP.WAIST_MEASURING_POSITION'),
        measuredParameter: t('MEASUREMENT.SECOND_STEP.WAIST_MEASURED_PARAMETER')
      }
    };
    return (
      <Formik
        enableReinitialize
        initialValues={new MeasurementsStepForm(this.p.gender, values)}
        validationSchema={MeasurementsStepSchema}
        onSubmit={(values: MeasurementsStepForm, actions: FormikActions<MeasurementsStepForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new MeasurementsStepForm(this.p.gender);
        }}
      >
        {props => {
          const { isValid, values } = props;
          return (
            <Form noValidate>
              <div className={classes.stepperContainer}>
                <div className={classes.measurementsStepContainer}>
                  {this.p.gender === 'FEMALE' ? (
                    <>
                      <Field
                        autoFocus
                        id="bustCircum"
                        name="bustCircum"
                        disabled={this.state.edit}
                        label={t('MEASUREMENT.BUST_CIRCUM')}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl
                          },
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>
                        }}
                        component={TextField}
                        onFocus={this.handleImage}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter' || e.keyCode === 13) {
                            // @ts-ignore
                            this.band && this.band!.focus();
                          }
                        }}
                        className={classes.textFieldMeasurementsStep}
                        margin="normal"
                        autoComplete="off"
                        variant="outlined"
                      />
                      <Field
                        id="bandCircum"
                        name="bandCircum"
                        disabled={this.state.edit}
                        label={t('MEASUREMENT.BAND_CIRCUM')}
                        required
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.formControl
                          },
                          endAdornment: <InputAdornment position="end">cm</InputAdornment>
                        }}
                        inputRef={(ref: any) => (this.band = ref)}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter' || e.keyCode === 13) {
                            // @ts-ignore
                            this.chestHeight && this.chestHeight!.focus();
                          }
                        }}
                        component={TextField}
                        className={classes.textFieldMeasurementsStep}
                        margin="normal"
                        onFocus={this.handleImage}
                        autoComplete="off"
                        variant="outlined"
                      />
                    </>
                  ) : null}
                  {this.p.gender === 'MALE' ? (
                    <Field
                      autoFocus
                      id="chestCircum"
                      name="chestCircum"
                      disabled={this.state.edit}
                      label={t('MEASUREMENT.CHEST_CIRCUM')}
                      required
                      inputRef={(ref: any) => (this.chest = ref)}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.formControl
                        },
                        endAdornment: <InputAdornment position="end">cm</InputAdornment>
                      }}
                      onKeyPress={(e: any) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          // @ts-ignore
                          this.chestHeight && this.chestHeight!.focus();
                        }
                      }}
                      component={TextField}
                      onFocus={this.handleImage}
                      className={classes.textFieldMeasurementsStep}
                      margin="normal"
                      autoComplete="off"
                      variant="outlined"
                    />
                  ) : null}
                  <Field
                    id="chestHeight"
                    name="chestHeight"
                    disabled={this.state.edit}
                    label={t('MEASUREMENT.CHEST_HEIGHT')}
                    required
                    inputRef={(ref: any) => (this.chestHeight = ref)}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl
                      },
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        // @ts-ignore
                        this.waist && this.waist!.focus();
                      }
                    }}
                    component={TextField}
                    onFocus={this.handleImage}
                    className={classes.textFieldMeasurementsStep}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Field
                    id="waist"
                    name="waist"
                    disabled={this.state.edit}
                    label={t('MEASUREMENT.WAIST')}
                    required
                    inputRef={(ref: any) => (this.waist = ref)}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl
                      },
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        // @ts-ignore
                        this.bodyHeight && this.bodyHeight!.focus();
                      }
                    }}
                    component={TextField}
                    onFocus={this.handleImage}
                    className={classes.textFieldMeasurementsStep}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Field
                    id="bodyHeight"
                    name="bodyHeight"
                    disabled={this.state.edit}
                    label={t('MEASUREMENT.BODY_HEIGHT')}
                    required
                    inputRef={(ref: any) => (this.bodyHeight = ref)}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl
                      },
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>
                    }}
                    component={TextField}
                    onFocus={this.handleImage}
                    className={classes.textFieldMeasurementsStep}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                </div>
                <div className={classes.avatarPosition}>
                  <img
                    className={classes.responsiveAvatar}
                    src={avatars[activeAvatar] && avatars[activeAvatar].avatar}
                    alt="avatar version"
                  />
                </div>
                {activeAvatar && (
                  <div className={classes.avatarInfoContainer}>
                    <div>
                      <Typography variant="h4">{avatars[activeAvatar] && avatars[activeAvatar].title}</Typography>
                      <p>
                        {t('MEASUREMENT.SECOND_STEP.MEASURING_POSITION')}{' '}
                        {avatars[activeAvatar] && avatars[activeAvatar].measuringPosition}
                      </p>
                      <p>
                        {t('MEASUREMENT.SECOND_STEP.MEASURED_PARAMETER')}{' '}
                        {avatars[activeAvatar] && avatars[activeAvatar].measuredParameter}
                      </p>
                    </div>
                    <div>
                      <p>
                        {t('MEASUREMENT.SECOND_STEP.OUTFIT_NOTE')}{' '}
                        {t('MEASUREMENT.SECOND_STEP.OUTFIT_INFO_' + this.p.gender)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.btnContainer}>
                <div className={classes.leftButton}>
                  <Button onClick={this.p.handleCancel} className={classes.button}>
                    {t('CANCEL')}
                  </Button>
                </div>
                <div className={classes.rightButton}>
                  <Button onClick={this.p.handleBack} className={classes.button}>
                    {t('BACK')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid && values.bodyHeight === ''}
                    className={classes.buttonNext}
                  >
                    {t('NEXT')}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: '#fff',
      position: 'absolute',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    stepperContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    textField: {
      width: '100%',
      maxWidth: 380
    },

    responsiveAvatar: {
      width: 'auto%',
      maxWidth: 300,
      height: '100%',
      maxHeight: 405
    },

    measurementsStepContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minWidth: 200,
      maxWidth: 380
    },

    avatarContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around'
    },

    avatarInfoContainer: {
      flex: 1,
      minWidth: 200
    },

    avatarPosition: {
      flex: 1,
      textAlign: 'center',
      minWidth: 320
    },

    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 372px)',
      position: 'fixed',
      bottom: 40,
      '@media (max-width:1200px)': {
        padding: 20,
        position: 'relative',
        bottom: 0,
        width: '100%'
      }
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: 'right',
      '@media (max-width:500px)': {
        display: 'flex',
        flexDirection: 'row'
      }
    },

    button: {
      width: '20%'
    },

    buttonNext: {
      marginLeft: 20,
      width: '20%',
      '@media (max-width:1200px)': {
        marginLeft: 10
      }
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(MeasurementsStep)));
