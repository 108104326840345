import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Button, Theme, Typography } from '@material-ui/core';

import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { COLORS } from '../../styles/colors';
import { TextField } from 'formik-material-ui';
import { Formik, FormikActions, Form, Field } from 'formik';
import createStyles from '@material-ui/core/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StringValidator } from '../../utils/validators';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DialogComponent } from '../DialogComponent';
import EndUserStore, { EndUser } from '../../stores/endUserStore';
import MenuItem from '@material-ui/core/MenuItem';
import CustomSelect from '../CustomSelectComponent';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { Autocomplete } from '../AutocompleteNew';
import ProjectStore from '../../stores/projectStore';
import GroupStore from '../../stores/groupStore';
import LocationStore from '../../stores/locationStore';
import AuthStore from '../../stores/authStore';
import List, { Header } from '../List';
import Female from '../../assets/sex/gender-female.svg';
import Male from '../../assets/sex/gender-male.svg';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MeasurementStore from '../../stores/measurementStore';
import Tooltip from '@material-ui/core/Tooltip';
import { toJS } from 'mobx';
import { dataToUpperCase } from '../../utils/dataToUpperCase';

interface MatchParams {
  id: string;
}

interface EndUserProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends EndUserProps, RouteComponentProps<MatchParams> {
  endUserStore: EndUserStore;
  authStore: AuthStore;
  measurementStore: MeasurementStore;
  projectStore: ProjectStore;
  groupStore: GroupStore;
  locationStore: LocationStore;
  classes: any;
  userId: string;
}

class EndUserForm {
  constructor(endUser?: EndUser) {
    if (endUser) {
      this.firstName = endUser.firstName;
      this.lastName = endUser.lastName;
      this.group = endUser.group.id;
      this.location = endUser.location.id;
      this.gender = endUser.gender;
      this.pid = endUser.pid;
    }
  }

  firstName: string = '';
  lastName: string = '';
  pid: string = '';
  group: string = '';
  location: string = '';
  gender: string = '';
}

const EndUserSchema = Yup.object().shape<EndUserForm>({
  firstName: StringValidator,
  lastName: StringValidator,
  pid: StringValidator,
  group: Yup.string()
    .required()
    .nullable(),
  location: Yup.string()
    .required()
    .nullable(),
  gender: StringValidator
});

interface EndUserState {
  status: boolean;
  edit: boolean;
  open: boolean;
  groups: { label: string; value: any }[];
  locations: { label: string; value: any }[];
}

@inject('endUserStore', 'projectStore', 'groupStore', 'locationStore', 'authStore', 'measurementStore')
@observer
class EndUserDetails extends Component<EndUserProps, EndUserState> {
  get p() {
    return this.props as InjectedProps;
  }
  firstName = null;
  lastName = null;
  pid = null;
  state = {
    status: true,
    edit: !this.p.match.params.id,
    open: false,
    groups: [],
    locations: []
  };

  headers = [
    {
      name: this.p.t('END_USER_LIST.CREATED_DATE'),
      key: 'measurement.createdDate',
      sort: true
    },

    {
      name: this.p.t('END_USER_LIST.CREATED_BY'),
      key: 'createdBy'
    },

    {
      name: this.p.t('END_USER_LIST.CHOSEN_LABEL'),
      key: 'chosenLabel'
    },

    {
      name: this.p.t('END_USER_LIST.BREASTS_LABEL'),
      key: 'breastsLabel'
    },

    {
      name: this.p.t('END_USER_LIST.V'),
      key: 'v'
    },

    {
      name: this.p.t('END_USER_LIST.CHEST_HEIGHT'),
      key: 'chestHeight'
    },

    {
      name: this.p.t('END_USER_LIST.BREASTS_UNDER'),
      key: 'breastsUnder'
    },

    {
      name: this.p.t('END_USER_LIST.WAIST'),
      key: 'waist'
    },

    {
      name: this.p.t('END_USER_LIST.HEIGHT'),
      key: 'height'
    },

    {
      name: this.p.t('END_USER_LIST.CHEST_WIDTH'),
      key: 'chestWidth'
    },

    {
      name: this.p.t('END_USER_LIST.BACK_CHOSEN_LABEL'),
      key: 'backChosenLabel'
    },

    {
      name: this.p.t('END_USER_LIST.COMMENT'),
      key: 'remark'
    }
  ];

  componentWillMount(): void {
    this.p.endUserStore.resetEndUser();
    this.p.projectStore.resetProject();
  }

  componentDidMount(): void {
    this.getProject(this.p.authStore.activeProject);
    if (this.p.match.params.id) {
      this.getEndUserDetails();
    }
  }

  submit = async (values: EndUserForm) => {
    dataToUpperCase(values)
    if (this.p.match.params.id) {
      this.editEndUser(values);
    } else {
      this.addEndUser(values);
    }
  };



  componentWillUnmount(): void {
    this.p.endUserStore.resetEndUser();
    this.p.projectStore.resetProject();
    this.p.measurementStore.setMeasurementList([]);
  }

  addEndUser = async (values: EndUserForm) => {
    const success = await this.p.endUserStore.addEndUserAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t('ERRORS.GLOBAL_ERROR'), {
        variant: 'error'
      });
    } else {
      this.p.enqueueSnackbar(this.p.t('END_USER.ADD_SUCCESS'), {
        variant: 'success'
      });
      this.p.history.replace('/end-users');
    }
  };

  editEndUser = async (values: EndUserForm) => {
    const id = this.p.match.params.id;
    const success = await this.p.endUserStore.editEndUserAction(values, id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t('ERRORS.GLOBAL_ERROR'), {
        variant: 'error'
      });
    } else {
      this.props.enqueueSnackbar(this.p.t('END_USER.UPDATE_SUCCESS'), {
        variant: 'success'
      });
      this.p.history.replace('/end-users');
    }
  };

  getEndUserDetails = async () => {
    if (this.p.match.params.id) {
      const result = await this.p.endUserStore.getEndUserByIdAction(this.p.match.params.id);
      if (!result) {
        this.props.enqueueSnackbar(this.p.t('ERRORS.CANT_GET_ITEM'), {
          variant: 'error'
        });
      } else {
        this.getProject(result.project.id);
        this.setLocationChoose(result.group.id);
        if (this.p.endUserStore.endUser.gender === 'FEMALE') {
          this.headers.splice(
            10,
            0,
            {
              name: this.p.t('END_USER_LIST.BREASTS_LABEL'),
              key: 'breastsLabel'
            },
            {
              name: this.p.t('END_USER_LIST.BREASTS_UNDER'),
              key: 'breastsUnder'
            },
            {
              name: this.p.t('END_USER_LIST.SUGGESTED_BREASTS_LABEL'),
              key: 'suggestedBreastsLabel'
            }
          );
        }
      }
    }
  };

  enableEdit = (resetForm: (values: any) => void, values: any, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetForm(values);
    this.setState({ edit: true });
  };

  disableEdit = () => {
    this.setState({ edit: false });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  deleteEndUser = async () => {
    const id = this.p.match.params.id;
    const success = await this.p.endUserStore.deleteEndUserAction(id);

    if (!success) {
      this.props.enqueueSnackbar(this.p.t('ERRORS.GLOBAL_ERROR'), {
        variant: 'error'
      });
    } else {
      this.props.enqueueSnackbar(this.p.t('END_USER.DELETE_SUCCESS'), {
        variant: 'success'
      });
      this.p.history.replace('/end-users');
    }
  };

  backToList = () => {
    this.p.history.goBack();
  };

  handleRowClick = (personId: string, id: string) => () => {
    this.p.history.push(`/measurements/details/${id}`);
  };

  getGroupsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    if (project) {
      this.setState({
        groups: project.groups
          .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
          .map((obj: any) => ({
            label: obj.name,
            value: obj.id
          }))
      });
    }
  };

  getLocationsOptions = async (search: string) => {
    // const project = this.p.projectStore.project;
    // if (project) {
    //   this.setState({
    //     locations: project.locations
    //       .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
    //       .map((obj: any) => ({
    //         label: obj.name,
    //         value: obj.id
    //       }))
    //   });
    // }
  };

  getProject = async (id: string) => {
    await this.p.projectStore.getProjectByIdAction(id);
  };

  getMeasurementList = (sortParams: any) => {
    this.p.measurementStore.getEndUserMeasurementListAction(this.p.match.params.id, sortParams);
  };

  setLocationChoose = (value: string) => {
    const location: any = [];
    const result = this.p.projectStore.project.groups.find((item: any) => item.id === value);
    const groupLocation = toJS(result && result.locations);
    const projectLocation = toJS(this.p.projectStore.project.locations.map((item: any) => item));
    projectLocation.map((item: any) => {
      if (groupLocation && groupLocation.indexOf(item.id) !== -1 && item.active === true) {
        location.push({ label: item.name, value: item.id });
      }
    });
    this.setState({ locations: location });
  };

  addMeasurement = () => {
    let endUserDetailId = this.p.match.params.id;
    this.p.history.push({
      pathname: `/end-users/${endUserDetailId}/measurements/add`
    });
  };

  render() {
    const {
      classes,
      t,
      endUserStore: { pending, endUser },
      measurementStore: { measurementList, measurementsTotalCount }
    } = this.p;
    const userId = this.p.match.params.id;
    return (
      <Formik
        enableReinitialize
        initialValues={new EndUserForm(endUser)}
        validationSchema={EndUserSchema}
        onSubmit={(values: EndUserForm, actions: FormikActions<EndUserForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new EndUserForm();
        }}
      >
        {props => {
          const { isValid, touched, errors, setFieldTouched, setFieldValue, values } = props;
          return (
            <>
              <Form noValidate>
                <div className={'titleContainer'}>
                  <BackIcon className={'backList'} onClick={this.backToList} />
                  <Typography
                    className={classNames(classes.dividerFullWidth, 'title')}
                    display="block"
                    variant="subtitle1"
                  >
                    {t('END_USER.TITLE')}
                  </Typography>
                </div>
                <Divider component="div" className={'divider'} />
                <div className={classes.container}>
                  <div className={classes.content}>
                    <Card className={classNames(classes.card, classes.cardContainer)}>
                      <CardContent className={classes.cardContent}>
                        <Field
                          id="firstName"
                          name="firstName"
                          disabled={!this.state.edit}
                          label={t('END_USER.NAME')}
                          required
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                              root: classes.formControl,
                              input: classes.transformText
                            }
                          }}
                          inputRef={(ref: any) => (this.firstName = ref)}
                          onKeyPress={(e: any) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              // @ts-ignore
                              this.lastName && this.lastName!.focus();
                            }
                          }}
                          component={TextField}
                          className={classes.textField}
                          margin="normal"
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Field
                          id="lastName"
                          name="lastName"
                          disabled={!this.state.edit}
                          label={t('END_USER.SURNAME')}
                          required
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                              root: classes.formControl,
                              input: classes.transformText
                            }
                          }}
                          inputRef={(ref: any) => (this.lastName = ref)}
                          onKeyPress={(e: any) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              // @ts-ignore
                              this.pid && this.pid!.focus();
                            }
                          }}
                          component={TextField}
                          className={classes.textField}
                          margin="normal"
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Field
                          id="pid"
                          name="pid"
                          disabled={!this.state.edit}
                          label={t('END_USER.PID')}
                          required
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline,
                              root: classes.formControl,
                              input: classes.transformText
                            }
                          }}
                          inputRef={(ref: any) => (this.pid = ref)}
                          component={TextField}
                          className={classes.textField}
                          margin="normal"
                          autoComplete="off"
                          variant="outlined"
                        />
                        <Autocomplete
                          error={errors.group}
                          onBlur={() => setFieldTouched('group')}
                          touched={touched.group}
                          label={t('END_USER.GROUP')}
                          options={this.state.groups}
                          disabled={!this.state.edit}
                          onInputChange={this.getGroupsOptions}
                          onValueChange={(value: any) => {
                            setFieldTouched('group');
                            setFieldValue('group', value);
                            setFieldValue('location', '-');
                            this.setLocationChoose(value);
                          }}
                          id="group"
                          name="group"
                          required
                          reset={values.group === '-'}
                          initialValue={{
                            label: endUser.group.name,
                            value: endUser.group.id
                          }}
                        />
                        <Autocomplete
                          error={errors.location}
                          touched={touched.location}
                          label={t('END_USER.LOCATION')}
                          onBlur={() => setFieldTouched('location')}
                          options={this.state.locations}
                          disabled={!this.state.edit}
                          onInputChange={this.getLocationsOptions}
                          onValueChange={(value: any) => {
                            setFieldTouched('location');
                            setFieldValue('location', value);
                          }}
                          id="location"
                          name="location"
                          required
                          reset={values.location === '-'}
                          initialValue={{
                            label: endUser.location.name,
                            value: endUser.location.id
                          }}
                        />
                        <CustomSelect
                          id="gender"
                          name="gender"
                          disabled={!this.state.edit || measurementList.length > 0}
                          value={values.gender}
                          onChange={(val: any) => {
                            setFieldValue('gender', val);
                            setFieldTouched('gender');
                          }}
                          label={t('MEASUREMENT.GENDER')}
                          error={errors.gender}
                        >
                          <MenuItem value="FEMALE">Female</MenuItem>
                          <MenuItem value="MALE">Male</MenuItem>
                        </CustomSelect>
                      </CardContent>
                      <CardActions className={classes.cardAction}>
                        {userId && (
                          <>
                            {!this.state.edit ? (
                              <>
                                <Button
                                  aria-label="Edit"
                                  type="button"
                                  className={classNames(classes.button, classes.btn)}
                                  onClick={event => this.enableEdit(props.resetForm, props.values, event)}
                                  disabled={pending}
                                >
                                  {pending ? <CircularProgress size={20} thickness={5} color="primary" /> : t('EDIT')}
                                </Button>
                                <Button
                                  aria-label="Delete"
                                  className={classNames(classes.button, classes.btn)}
                                  onClick={this.handleClickOpenDialog}
                                >
                                  {pending ? <CircularProgress size={20} thickness={5} color="primary" /> : t('DELETE')}
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  aria-label="Save"
                                  className={classNames(classes.button, classes.btn)}
                                  type="submit"
                                  disabled={!isValid}
                                >
                                  {pending ? <CircularProgress size={20} thickness={5} color="primary" /> : t('SAVE')}
                                </Button>
                                <Button
                                  aria-label="Delete"
                                  className={classNames(classes.button, classes.btn)}
                                  onClick={this.disableEdit}
                                >
                                  {pending ? <CircularProgress size={20} thickness={5} color="primary" /> : t('CANCEL')}
                                </Button>
                              </>
                            )}
                            <DialogComponent
                              title={'END_USER.END_USER_DELETE'}
                              t={t}
                              classes={classes}
                              submitButtonTitle={'DELETE'}
                              cancel={'CANCEL'}
                              pending={pending}
                              handleClickCloseDialog={this.handleCloseDialog}
                              handleDelete={this.deleteEndUser}
                              open={this.state.open}
                            />
                          </>
                        )}
                        {!this.p.match.params.id && (
                          <Button
                            aria-label="Save"
                            className={classNames(classes.fab, classes.btn)}
                            type="submit"
                            disabled={!isValid}
                          >
                            {pending ? <CircularProgress size={20} thickness={5} color="primary" /> : t('SAVE')}
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </div>
                </div>
              </Form>
              {this.p.match.params.id && (
                <>
                  <div className={classes.measurementBtnPosition}>
                    <Button
                      aria-label="Save"
                      onClick={this.addMeasurement}
                      className={classes.btnMeasurement}
                      type="button"
                    >
                      {t('END_USER.ADD_MEASUREMENT')}
                    </Button>
                  </div>
                  <List
                    data={measurementList}
                    getData={this.getMeasurementList}
                    headers={this.headers}
                    totalCount={measurementsTotalCount}
                    headerClass={classes.headerPadding}
                  >
                    {measurementList &&
                      measurementList.length &&
                      measurementList.map((item: any, i: number) => (
                        <TableRow
                          key={item.uuid || item.id || 'row-' + i}
                          className={'rowList'}
                          onClick={this.handleRowClick(this.p.match.params.id, item.id)}
                        >
                          {this.headers.map((header: Header, index: number) => {
                            switch (header.key) {
                              case 'gender':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item[header.key] === 'FEMALE' ? (
                                      <img className={classes.genderColor} src={Female} alt="female" />
                                    ) : (
                                      <img className={classes.genderColor} src={Male} alt="male" />
                                    )}
                                  </TableCell>
                                );
                              case 'project':
                              case 'location':
                              case 'group':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item[header.key].name}
                                  </TableCell>
                                );
                              case 'chestHeight':
                              case 'chestWidth':
                              case 'chosenId':
                              case 'chosenLabel':
                              case 'height':
                              case 'suggestedLabel':
                              case 'waist':
                              case 'backChosenId':
                              case 'backChosenLabel':
                              case 'breastsLabel':
                              case 'breastsUnder':
                              case 'suggestedBreastsLabel':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item && item[header.key] ? item[header.key] : '-'}
                                  </TableCell>
                                );
                              case 'measurement.createdDate':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item && item['createdDate'] ? item['createdDate'] : '-'}
                                  </TableCell>
                                );
                              case 'v':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {(item && item[header.key]) || item[header.key] === 0 ? item[header.key] : '-'}
                                  </TableCell>
                                );
                              case 'createdBy':
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item && item.createdBy.username}
                                  </TableCell>
                                );
                              case 'remark':
                                return (
                                  <Tooltip
                                    key={`row-${i}-col-${index}`}
                                    title={
                                      item[header.key] ? (
                                        <React.Fragment>
                                          <Typography color="inherit">{item[header.key]}</Typography>
                                        </React.Fragment>
                                      ) : (
                                        ''
                                      )
                                    }
                                  >
                                    <TableCell className={classNames(classes.tableCell, classes.remark)}>
                                      {item && item[header.key] ? item[header.key].slice(0, 40) : '-'}
                                      {item && item[header.key] && item[header.key].length > 40 ? '...' : ''}
                                    </TableCell>
                                  </Tooltip>
                                );
                              default:
                                return (
                                  <TableCell key={`row-${i}-col-${index}`} className={classes.tableCell}>
                                    {item[header.key]}
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      ))}
                  </List>
                </>
              )}
            </>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      backgroundSize: 'cover'
    },

    transformText: {
      textTransform: "uppercase"
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
      maxWidth: 380,
      marginTop: 12
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    content: {
      width: '100%',
      padding: 20,

      '@media (max-width:1200px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },

    cardContainer: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      margin: '0 auto',
      boxShadow: 'none',
      overflow: 'visible'
    },

    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    select: {
      width: '80%',
      maxWidth: 380,
      height: 56,
      marginTop: 10,
      borderRadius: 5,
      borderColor: COLORS.WHITE
    },

    btn: {
      margin: '0 10px',
      width: '10%',
      background: COLORS.PRIMARY_DARK,
      color: COLORS.WHITE
    },

    cardAction: {
      justifyContent: 'center'
    },

    btnColor: {
      color: COLORS.WHITE,
      '&:hover': {
        background: COLORS.PRIMARY_DARK
      }
    },

    tableCell: {
      textAlign: 'left'
    },

    headerPadding: {
      textAlign: 'left'
    },

    btnMeasurement: {
      fontSize: 13,
      background: COLORS.PRIMARY_DARK,
      color: COLORS.WHITE,
      marginBottom: 20
    },

    measurementBtnPosition: {
      textAlign: 'right'
    },

    remark: {
      whiteSpace: 'nowrap'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(EndUserDetails)));
