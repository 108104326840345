import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEN from '../translations/en.json';

i18n.use(initReactI18next).init({
  debug: true,
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translationsEN
    }
  },
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
});

export default i18n;
