import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-symbol/implement';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

let version: {
  data: {
    name: string;
    version: string;
    commitHash: string;
    buildDate: string;
  };
};
const printVersionInfo = async () => {
  const styles = 'background: #3f3d3d; color: #fff; padding: 5px 10px; ';
  const request = {
    url: './version.json',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  version = await axios(request);
  console.log(`%c${version.data.name}`, `${styles} font-size: 18px`);
  console.log(`%cVersion: ${version.data.version}`, styles);
  console.log(`%cCommit hash: ${version.data.commitHash}`, styles);
  console.log(`%cBuild date: ${version.data.buildDate}`, styles);
  console.log(`%cPowered by Maio Software House`, styles);
  console.log(`%cmaiosoftwarehouse.com`, styles);
  if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'stage') {
    Sentry.init({ dsn: 'https://822999204fb340078a1a50573e41bfec@sentry.io/1526318' });

    Sentry.configureScope((scope: any) => {
      scope.setTag('environment', process.env.REACT_APP_ENV);
      scope.setTag('version TAG', version.data.version);
      scope.setTag('commit', version.data.commitHash);
      scope.setTag('buildDate', version.data.buildDate);
    });
  }
};
printVersionInfo();
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
