import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import EndUserStore from '../../../stores/endUserStore';
import * as Yup from 'yup';
import { StringValidator } from '../../../utils/validators';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../../styles/colors';
import CustomSelect from '../../CustomSelectComponent';
import { Autocomplete } from '../../AutocompleteNew';
import LocationStore from '../../../stores/locationStore';
import GroupStore from '../../../stores/groupStore';
import ProjectStore from '../../../stores/projectStore';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import AuthStore from '../../../stores/authStore';
import AccountStore from '../../../stores/accountStore';
import classNames from 'classnames';
import MeasurementStore from '../../../stores/measurementStore';
import { toJS } from 'mobx';
import { dataToUpperCase } from '../../../utils/dataToUpperCase';

interface MatchParams {
  id: string;
}

interface UserStepProps extends WithTranslation, InjectedNotistackProps {
  handleNext: (values: UserStepForm, id: string) => void;
  skipMeasurement: (values: UserStepForm, id: string) => void;
  values: UserStepForm;
  edit: boolean;
  endUserDetailId?: any;
  endUserId?: string;
}

interface InjectedProps extends UserStepProps, RouteComponentProps<MatchParams> {
  groupStore: GroupStore;
  projectStore: ProjectStore;
  locationStore: LocationStore;
  endUserStore: EndUserStore;
  authStore: AuthStore;
  accountStore: AccountStore;
  measurementStore: MeasurementStore;
  classes: any;
}

export class UserStepForm {
  constructor(values?: any) {
    if (values) {
      this.firstName = values.firstName || '';
      this.lastName = values.lastName || '';
      this.group = (values.group && values.group.id) || '';
      this.location = (values.location && values.location.id) || '';
      this.pid = values.pid;
      this.gender = values.gender || '';
    }
  }

  firstName: string = '';
  lastName: string = '';
  group: string = '';
  location: string = '';
  gender: string = '';
  pid: string = '';
}

const UserStepSchema = Yup.object().shape<UserStepForm>({
  firstName: StringValidator,
  pid: StringValidator,
  lastName: StringValidator,
  group: StringValidator,
  location: StringValidator,
  gender: StringValidator
});

interface UserStepState {
  searchPID: any;
  findPID: boolean;
  checkPID: boolean
  endUser: any;
  groups: { label: string; value: any }[];
  locations: { label: string; value: any }[];
  refresh: boolean;
}

@inject('projectStore', 'groupStore', 'endUserStore', 'locationStore', 'authStore', 'measurementStore', 'accountStore')
@observer
class UserStep extends Component<UserStepProps, UserStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  firstName = null;
  lastName = null;
  group = null;
  location = null;
  gender = null;

  state = {
    checkPID:false,
    searchPID: '',
    endUser: {
      id: '',
      firstName: '',
      lastName: '',
      pid: '',
      gender: '',
      group: {
        active: true,
        id: '',
        name: ''
      },
      location: {
        active: true,
        id: '',
        name: ''
      },
      project: {
        groups: [],
        id: '',
        locations: [],
        name: '',
        type: ''
      }
    },
    groups: [],
    locations: [],
    refresh: false,
    findPID: true
  };

  submit = async (values: UserStepForm) => {
    let result;
    dataToUpperCase(values);
    if (this.state && this.state.endUser && this.state.searchPID === '') {
      result = await this.p.endUserStore.editEndUserAction(values, this.state.endUser.id);
    } else {
      result = await this.p.endUserStore.addEndUserAction(values);
    }

    if (result) {
      this.p.handleNext(values, result);
    }
  };

  skippedMeasurement = async (values: UserStepForm) => {
    let result;
    if (this.state && this.state.endUser && this.state.searchPID === '') {
      result = await this.p.endUserStore.editEndUserAction(values, this.state.endUser.id);
    } else {
      result = await this.p.endUserStore.addEndUserAction(values);
    }

    if (result) {
      this.p.measurementStore.getSizeMatrix(values.gender);
      this.p.skipMeasurement(values, result);
    }
  };

  componentDidMount(): void {
    this.p.projectStore.getProjectByIdAction(this.p.authStore.activeProject);
    this.getEndUserById();
    if (this.p.endUserDetailId) {
      this.getEndUserByIdRef();
    }
  }

  getEndUserById = async () => {
    if (this.p.endUserId) {
      const result = await this.p.endUserStore.getEndUserByIdAction(this.p.endUserId);
      if (result) {
        this.setState({ endUser: result });
      }
    }
  };

  getEndUserByIdRef = async () => {
    if (this.p.endUserDetailId) {
      const result = await this.p.endUserStore.getEndUserByIdAction(this.p.endUserDetailId);
      if (result) {
        this.setState({ endUser: result, findPID: false, checkPID:true });
        this.setLocationChoose(result.group.id);
      }
    }
  };

  handlePID = async (pid: string, resetForm?: any) => {
    this.setState({ refresh: true });
    const result = await this.p.endUserStore.getEndUserByPID(pid.toUpperCase());
    if (result) {
      this.getProject(result.project.id);
      this.setState({searchPID: ''})
      resetForm(new UserStepForm(result));
      this.setState({ endUser: result });
      this.setLocationChoose(result.group.id);
      this.props.enqueueSnackbar(this.p.t('END_USER.GET_DATA'), {
        variant: 'success'
      });
    } else {
      this.props.enqueueSnackbar(this.p.t('END_USER.NOT_FOUND'), {
        variant: 'info'
      });
      this.setState({
        findPID: false,
        searchPID: pid,
        endUser: {
          firstName: '',
          lastName: '',
          pid: pid,
          gender: '',
          group: {
            active: true,
            id: '',
            name: ''
          },
          location: {
            active: true,
            id: '',
            name: ''
          },
          project: {
            groups: [],
            id: '',
            locations: [],
            name: '',
            type: ''
          }
        }
      });
      resetForm(new UserStepForm(''));
    }
    this.setState({ refresh: false });
    this.setState({ checkPID: true });
  };

  setLocationChoose = (value: string) => {
    const location: any = [];
    const result = this.p.projectStore.project.groups.find((item: any) => item.id === value);
    const groupLocation = toJS(result && result.locations);
    const projectLocation = toJS(this.p.projectStore.project.locations.map((item: any) => item));
    projectLocation.map((item: any) => {
      if (groupLocation && groupLocation.indexOf(item.id) !== -1 && item.active === true) {
        location.push({ label: item.name, value: item.id });
      }
    });
    this.setState({ locations: location });
  };

  getGroupsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    if (project) {
      this.setState({
        groups: project.groups
          .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
          .map((obj: any) => ({
            label: obj.name,
            value: obj.id
          }))
      });
    }
  };

  getLocationsOptions = async (search: string) => {
    const project = this.p.projectStore.project;
    // if (project) {
    //   this.setState({
    //     locations: project.locations
    //       .filter((item: any) => item.active && item.name.toLowerCase().includes(search.toLowerCase()))
    //       .map((obj: any) => ({
    //         label: obj.name,
    //         value: obj.id
    //       }))
    //   });
    // }
  };

  getProjectsOptions = async (search: string) => {
    await this.p.projectStore.getProjectOptionsAutocompleteAction({
      name: search
    });
  };

  getProject = async (id: string) => {
    await this.p.projectStore.getProjectByIdAction(id);
  };

  render() {
    const { classes, t } = this.p;
    const isIE = window.navigator.userAgent.indexOf('Trident') !== -1;
    console.log(this.p.accountStore.account.extendedMeasurement);
    return (
      <div className={classes.stepContainer}>
        {!this.state.refresh && (
          <Formik
            enableReinitialize
            initialValues={
              new UserStepForm(this.state && this.state.endUser ? this.state.endUser : this.state.searchPID)
            }
            validationSchema={UserStepSchema}
            onSubmit={(values: UserStepForm, actions: FormikActions<UserStepForm>) => {
              this.submit(values).then(() => {
                actions.setSubmitting(false);
              });
            }}
            onReset={values => {
              values = new UserStepForm();
            }}
          >
            {props => {
              const { isValid, resetForm, setFieldValue, setFieldTouched, values, errors, touched } = props;
              return (
                <Form
                  noValidate
                  className={classes.endUserInfo}
                  onChange={(event: any) => {
                    if (event.target.name === 'pid' && !this.state.endUser.id) {
                      this.setState({ findPID: true });
                    }
                  }}
                >
                  <Field
                    id="pid"
                    name="pid"
                    label={t('MEASUREMENT.PID')}
                    required
                    value={!this.state.refresh && this.state.searchPID}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        this.handlePID(values.pid, resetForm);
                      }
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={(!isValid && values.pid === '') || !this.state.findPID}
                    onClick={() => this.handlePID(values.pid, resetForm)}
                    className={classes.button}
                  >
                    {t('CHECK')}
                  </Button>
                  <Field
                    id="firstName"
                    name="firstName"
                    label={t('MEASUREMENT.NAME')}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    inputRef={(ref: any) => (this.firstName = ref)}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        // @ts-ignore
                        this.lastName && this.lastName!.focus();
                      }
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Field
                    id="lastName"
                    name="lastName"
                    label={t('MEASUREMENT.SURNAME')}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl,
                        input: classes.transformText
                      }
                    }}
                    inputRef={(ref: any) => (this.lastName = ref)}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                  <Autocomplete
                    error={errors.group}
                    onBlur={() => setFieldTouched('group')}
                    touched={touched.group}
                    label={t('MEASUREMENT.GROUP')}
                    options={this.state && this.state.groups}
                    onInputChange={this.getGroupsOptions}
                    onValueChange={(value: any) => {
                      setFieldTouched('group');
                      setFieldValue('group', value);
                      setFieldValue('location', '-');
                      this.setLocationChoose(value);
                    }}
                    id="group"
                    name="group"
                    required
                    reset={values.group === '-'}
                    initialValue={{
                      label: this.state && this.state.endUser && this.state.endUser.group.name,
                      value: this.state && this.state.endUser && this.state.endUser.group.id
                    }}
                  />
                  <Autocomplete
                    error={errors.location}
                    touched={touched.location}
                    label={t('MEASUREMENT.LOCATION')}
                    onBlur={() => setFieldTouched('location')}
                    options={this.state && this.state.locations}
                    onInputChange={this.getLocationsOptions}
                    onValueChange={(value: any) => {
                      setFieldTouched('location');
                      setFieldValue('location', value);
                    }}
                    id="location"
                    name="location"
                    required
                    reset={values.location === '-'}
                    initialValue={{
                      label: this.state && this.state.endUser && this.state.endUser.location.name,
                      value: this.state && this.state.endUser && this.state.endUser.location.id
                    }}
                  />
                  <CustomSelect
                    id="gender"
                    name="gender"
                    value={values.gender}
                    onChange={(val: any) => {
                      setFieldValue('gender', val);
                      setFieldTouched('gender');
                    }}
                    label={t('MEASUREMENT.GENDER')}
                    error={errors.gender}
                  >
                    <MenuItem value="FEMALE">{t('MEASUREMENT.FEMALE')}</MenuItem>
                    <MenuItem value="MALE">{t('MEASUREMENT.MALE')}</MenuItem>
                  </CustomSelect>
                  <div className={!isIE ? classes.btnContainer : classes.btnContainerIe}>
                    <div>
                      {this.p.accountStore.account.extendedMeasurement && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.skippedMeasurement(values)}
                          disabled={(!isValid && values.gender === '') || !this.state.checkPID}
                          className={classNames(classes.button, classes.skipBtn)}
                        >
                          {t('SKIP')}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={(!isValid && values.gender === '') || !this.state.checkPID}
                        type="submit"
                        className={classes.button}
                      >
                        {t('NEXT')}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: '#fff',
      position: 'absolute',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    transformText: {
      textTransform: "uppercase"
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    textField: {
      width: '100%',
      maxWidth: 380
    },

    btnContainerIe: {
      width: '40%',
      bottom: 40,
      position: 'fixed',
      textAlign: 'right'
    },

    btnContainer: {
      textAlign: 'right',
      width: 'calc(100% - 372px)',
      position: 'fixed',
      bottom: 40,
      '@media (max-width:1200px)': {
        padding: 20,
        position: 'relative',
        bottom: 0,
        width: '100%'
      }
    },

    endUserInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      overflow: 'visible'
    },

    skipBtn: {
      marginRight: 20
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(UserStep)));
