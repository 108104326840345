export const COLORS = {
  PRIMARY: '#a4adb6',
  PRIMARY_DARK: '#515d66',
  SECONDARY: '#dddedf',
  BLACK: '#000000',
  GREY: '#3a3a3b',
  WHITE: '#FFFFFF',
  GOLD: '#8d8053',
  DIALOG_COLOR: 'rgba(66, 66, 66, 0.90)',
  ERROR: '#c1352a'
};
