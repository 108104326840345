import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../styles/colors';
import background from '../../assets/images/bg.jpg';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100vh',
      width: '100%',
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${background}) no-repeat fixed center center`,
      backgroundSize: 'cover',
      flexDirection: 'column'
    },

    loginContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flex: 1,
      '@media (max-width:1200px)': {
        flexDirection: 'column'
      }
    },

    media: {
      maxWidth: 210,
      width: '100%',
      height: 'auto',
      backgroundSize: 'contain'
    },

    forgotContainer: {
      textAlign: 'center',
      marginTop: 50,
      '& a': {
        textDecoration: 'none',
        color: COLORS.WHITE,
        fontFamily: '"RbFontLight",serif !important',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    accent: {
      color: COLORS.PRIMARY
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      maxWidth: 380,
      marginTop: 12
    },

    textFieldPassword: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      maxWidth: 380,
      marginTop: 4
    },
    textFieldConfirmPassword: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      marginTop: 20,
      maxWidth: 380
    },
    button: {
      display: 'flex',
      margin: '20px auto',
      fontWeight: 600,
      fontSize: 14,
      backgroundColor: COLORS.PRIMARY_DARK,
      color: COLORS.WHITE,
      width: 75,
      height: 36
    },
    buttonWidth: {
      width: '100%',
      minWidth: 100,
      maxWidth: 180,
      textTransform: 'capitalize'
    },
    logo: {
      maxWidth: 400,
      width: '100%',
      height: 'auto',
      padding: 20,
      '@media (max-width:768px)': {
        width: '50%'
      }
    },

    logoContainer: {
      '@media (max-width:1200px)': {
        padding: 20
      }
    },

    center: {
      textAlign: 'center',
      marginTop: 140,
      '@media (max-width:1200px)': {
        marginTop: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },

    title: {
      fontSize: 78,
      color: COLORS.PRIMARY,
      textAlign: 'center',
      letterSpacing: 5,
      fontFamily: '"RbFontExtraLight",serif !important',
      '@media (max-width:768px)': {
        fontSize: 50
      }
    },

    subtitle: {
      color: COLORS.GOLD,
      fontSize: 48,
      fontFamily: '"RbFontBook",serif !important',
      '@media (max-width:768px)': {
        fontSize: 40
      }
    },

    spacing: {
      height: 56,
      marginTop: 12,
      marginBottom: 8
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    control: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    content: {
      width: '25%',
      '@media (max-width:1200px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },

    dialog: {
      textAlign: 'center',
      background: COLORS.DIALOG_COLOR,
      borderRadius: 10,
      width: '100%'
    },

    btn: {
      color: COLORS.WHITE,
      background: COLORS.PRIMARY_DARK,
      width: '30%',
      margin: '10px 0',
      '@media (max-width:500px)': {
        width: '50%',
        maxWidth: 150
      }
    },

    btnDialog: {
      justifyContent: 'center',
      flexDirection: 'column'
    }
  });

export default styles;
