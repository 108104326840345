import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Button, Typography } from '@material-ui/core';

import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import AuthStore from '../../stores/authStore';
import { TextField } from 'formik-material-ui';
import Logo from '../../assets/images/sf_logo.svg';
import { Formik, FormikActions, Form, Field } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PasswordValidator } from '../../utils/validators';
import AccountStore from '../../stores/accountStore';
import styles from './style';

interface MatchParams {
  token: string;
}

interface SetPasswordProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends SetPasswordProps, RouteComponentProps<MatchParams> {
  authStore: AuthStore;
  accountStore: AccountStore;
  password: string;
  confirmPassword: string;
  classes: any;
}

class SetPasswordForm {
  password: string = '';
  confirmPassword: string = '';
}

const SetPasswordSchema = Yup.object().shape<SetPasswordForm>({
  password: PasswordValidator,
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'Passwords must match')
});

@inject('authStore', 'accountStore')
@observer
class SetPassword extends Component<SetPasswordProps> {
  get p() {
    return this.props as InjectedProps;
  }

  submit = async (values: SetPasswordForm) => {
    const { t } = this.props;
    const success = await this.p.accountStore.resetPasswordAction({
      password: values.password,
      token: this.p.match.params.token
    });
    if (!success) {
      this.props.enqueueSnackbar(this.p.t('ERRORS.GLOBAL_ERROR'), {
        variant: 'error'
      });
    } else {
      this.p.history.push('/login');
      this.p.enqueueSnackbar(t('FORGOT_PASSWORD.SET_PASSWORD'), {
        variant: 'success'
      });
    }
  };

  render() {
    const {
      classes,
      t,
      authStore: { pending }
    } = this.p;

    return (
      <Formik
        initialValues={new SetPasswordForm()}
        validationSchema={SetPasswordSchema}
        onSubmit={(values: SetPasswordForm, actions: FormikActions<SetPasswordForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {props => {
          const { isSubmitting, isValid } = props;
          return (
            <div className={classes.container}>
              <div className={classes.logo}>
                <img src={Logo} alt="Logo" />
              </div>
              <div className={classes.loginContainer}>
                <div className={classes.logoContainer}>
                  <Typography variant="h1" className={classes.title}>
                    SizeFit 3
                  </Typography>
                  <Typography variant="h2" className={classes.subtitle}>
                    {t('CLIENT')}
                  </Typography>
                </div>
                <div className={classes.content}>
                  <Form className={classes.center}>
                    <Field
                      id="password"
                      name="password"
                      label={t('LABELS.PASSWORD')}
                      component={TextField}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.control
                        }
                      }}
                      className={classes.textFieldPassword}
                      margin="normal"
                      autoComplete="off"
                      type="password"
                      variant="outlined"
                    />
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      label={t('LABELS.CONFIRM_PASSWORD')}
                      component={TextField}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.control
                        }
                      }}
                      className={classes.textFieldConfirmPassword}
                      margin="normal"
                      autoComplete="off"
                      type="password"
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid}
                      className={classNames(classes.button, classes.buttonWidth)}
                      type="submit"
                    >
                      {pending ? (
                        <CircularProgress size={20} thickness={5} color="secondary" />
                      ) : (
                        <div>{t('LABELS.SET_PASSWORD')}</div>
                      )}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(withTranslation()(withSnackbar(SetPassword)));
