import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Theme, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import { PasswordValidator } from '../../utils/validators';
import { withTranslation, WithTranslation } from 'react-i18next';
import AccountStore from '../../stores/accountStore';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../styles/colors';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';

interface PasswordProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends PasswordProps {
  accountStore: AccountStore;
  classes: any;
  userId: string;
}

class PasswordForm {
  constructor() {
    this.password = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }

  password: string;
  newPassword: string;
  confirmPassword: string;
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required(),
  newPassword: PasswordValidator,
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
});

@inject('accountStore')
@observer
class PasswordChange extends Component<PasswordProps> {
  get p() {
    return this.props as InjectedProps;
  }

  submit = async (values: PasswordForm) => {
    this.changePassword(values);
  };

  changePassword = async (values: PasswordForm) => {
    const success = await this.p.accountStore.changePasswordAction(values);
    if (!success) {
      this.p.enqueueSnackbar(this.p.t('PROFILE.WRONG_PASSWORD'), {
        variant: 'error'
      });
    } else {
      this.p.enqueueSnackbar(this.p.t('PROFILE.PASSWORD_UPDATED'), {
        variant: 'success'
      });
    }
  };

  render() {
    const { classes, t } = this.p;
    return (
      <Formik
        enableReinitialize
        initialValues={new PasswordForm()}
        validationSchema={PasswordSchema}
        onSubmit={(values: PasswordForm, actions: FormikActions<PasswordForm>) => {
          this.submit(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        onReset={values => {
          values = new PasswordForm();
        }}
      >
        {props => {
          const { isSubmitting, isValid } = props;
          return (
            <Form noValidate className={classes.passwordForm}>
              <div className={classes.title}>
                <Typography
                  className={classNames(classes.dividerFullWidth, classes.passwordTitle)}
                  color="textSecondary"
                  display="block"
                  variant="subtitle1"
                >
                  {t('PROFILE.CHANGE_PASSWORD')}
                </Typography>
                <Divider component="div" className={classes.divider} />
              </div>
              <Field
                id="password"
                name="password"
                label={t('PROFILE.PASSWORD')}
                required
                type="password"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Field
                id="newPassword"
                name="newPassword"
                label={t('PROFILE.NEW_PASSWORD')}
                required
                type="password"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Field
                id="confirmPassword"
                name="confirmPassword"
                label={t('PROFILE.CONFIRM_PASSWORD')}
                required
                type="password"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.formControl
                  }
                }}
                component={TextField}
                className={classes.textField}
                margin="normal"
                autoComplete="off"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.passwordBtn)}
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {t('PROFILE.CHANGE_PASSWORD')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
      maxWidth: 380,
      marginTop: 12
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    divider: {
      width: '100%',
      marginBottom: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },

    passwordTitle: {
      marginTop: 20,
      alignSelf: 'flex-start',
      fontFamily: '"RbFontLight",serif !important'
    },

    passwordBtn: {
      marginTop: 20
    },

    passwordForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    title: {
      textAlign: 'left',
      width: '30%',
      '@media (max-width:500px)': {
        width: '100%'
      }
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(PasswordChange)));
