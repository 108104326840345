import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import createStyles from '@material-ui/core/styles/createStyles';

import { RouteComponentProps } from 'react-router';
import InputLabel from '@material-ui/core/InputLabel';
import { WithTranslation, withTranslation } from 'react-i18next';

interface MatchParams {}

interface MatrixProps extends WithTranslation {
  value: string;
  recommended: string;
  matrix: string[][];
  suggestions: string[];
  onChange: (item: string) => void;
}

interface InjectedProps extends MatrixProps, RouteComponentProps<MatchParams> {
  classes: any;
}

interface MatrixState {
  selected: { i: number; j: number };
  selectedDefault: { i: number; j: number };
}

class Matrix extends Component<MatrixProps, MatrixState> {
  get p() {
    return this.props as InjectedProps;
  }

  setActive = (item: string) => () => {
    this.p.onChange(item);
  };

  render() {
    const { classes, t, value, suggestions } = this.p;
    return (
      <div className={classes.matrixContainer}>
        <InputLabel className={classes.label}>{t('MEASUREMENT.SELECT_SIZE')}</InputLabel>
        <div className={classes.matrix}>
          {this.p.matrix.map((row: string[]) => (
            <div className={classes.row} key={row[0] + 'row'}>
              {row.map((item: string) => (
                <div
                  key={item}
                  onClick={this.setActive(item)}
                  className={classNames({
                    [classes.cell]: true,
                    [classes.selected]: value === item,
                    [classes.suggestion]: suggestions.find((suggest: string) => suggest === item)
                  })}
                >
                  {item}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    matrixContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '30px 0'
    },
    label: {
      marginBottom: 10
    },
    matrix: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch'
    },
    cell: {
      display: 'flex',
      flex: '1',
      padding: 16,
      minWidth: 60,
      justifyContent: 'center',
      fontWeight: 700,
      border: '1px solid rgba(255, 255, 255, 0.3)',
      cursor: 'pointer',
      userSelect: 'none',
      '@media (min-width:1921px)': {
        fontSize: '24px'
      },
      '@media (max-width:1130px)': {
        padding: 6,
        minWidth: 35
      }
    },
    selected: {
      background: 'rgba(255, 255, 255, 0.6) !important'
    },

    suggestion: {
      background: 'rgba(255, 255, 255, 0.2)'
    }
  });

export default withStyles(styles)(withTranslation()(Matrix));
