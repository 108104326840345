import React, { Component } from 'react';
import { FormControlLabel, Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikActions } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import * as Yup from 'yup';
import { StringValidator } from '../../../utils/validators';
import createStyles from '@material-ui/core/styles/createStyles';
import { COLORS } from '../../../styles/colors';
import CustomSelect from '../../CustomSelectComponent';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import AccountStore from '../../../stores/accountStore';
import MeasurementStore from '../../../stores/measurementStore';
import Check from '@material-ui/icons/RadioButtonChecked';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
// @ts-ignore
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import FemalePanel from '../../../assets/breast/female_panel_side_dark.png';
import classNames from 'classnames';

interface MatchParams {
  id: string;
}

interface UserStepProps extends WithTranslation, InjectedNotistackProps {
  handleBackAfterSkip: () => void;
  handleNextAfterSkip: (event: any) => void;
  handleCancel: () => void;
  handleBackCheckbox: () => void;
  values: SizesStepForm;
  endUserDetailId?: any;
  endUserId?: string;
  gender: string;
  skipped: boolean;
  backSize: boolean;
}

interface InjectedProps extends UserStepProps, RouteComponentProps<MatchParams> {
  accountStore: AccountStore;
  measurementStore: MeasurementStore;
  classes: any;
}

const Breast = [FemalePanel];

export class SizesStepForm {
  constructor(gender: string, values?: any) {
    if (values) {
      this.sizing = values.sizing;
      this.sizeNumber = values.sizeNumber;
      this.remark = values.remark || '';
      this.backSize = values.backSize || '';
      this.breastSize = values.breastSize;
      this.gender = gender;
    }
  }

  sizing: any = '';
  breastSize: string = '';
  sizeNumber: any = '';
  remark: string = '';
  backSize: string = '';
  gender: string = '';
}

const SizeStepSchema = Yup.object().shape<SizesStepForm>({
  sizing: StringValidator,
  sizeNumber: StringValidator,
  remark: Yup.string(),
  breastSize: Yup.string().when('gender', {
    is: val => val === 'FEMALE',
    then: StringValidator
  }),
  backSize: Yup.string(),
  gender: Yup.string()
});

interface SizeStepState {
  sizing?: { sizes: string; value: any }[];
  open?: boolean;
  visible: number;
}

@inject('accountStore', 'measurementStore')
@observer
class SkipMeasurement extends Component<UserStepProps, SizeStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    visible: 0
  };

  submit = async (values: SizesStepForm) => {
    this.p.handleNextAfterSkip(values);
  };

  show = (index: number) => {
    this.setState({ visible: index });
  };

  hide = () => {
    this.setState({ visible: 0 });
  };

  getBreast = () => {
    return Breast[0];
  };

  render() {
    const {
      classes,
      t,
      values,
      measurementStore: { sizing }
    } = this.p;
    const isIE = window.navigator.userAgent.indexOf('Trident') !== -1;
    const sizingNumberRow: any[] = [];

    for (let i = 1; i <= Number(sizing.lengthMaximal); i++) {
      sizingNumberRow.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return (
      <div className={classes.stepContainer}>
        <Formik
          enableReinitialize
          initialValues={new SizesStepForm(this.p.gender, values)}
          validationSchema={SizeStepSchema}
          onSubmit={(values: SizesStepForm, actions: FormikActions<SizesStepForm>) => {
            this.submit(values).then(() => {
              actions.setSubmitting(false);
            });
          }}
          onReset={values => {
            values = new SizesStepForm(this.p.gender, values);
          }}
        >
          {props => {
            const { isSubmitting, setFieldValue, setFieldTouched, values } = props;
            return (
              <Form noValidate className={classes.endUserInfo}>
                <div className={classes.endUserForm}>
                  <CustomSelect
                    id="sizing"
                    name="sizing"
                    value={values.sizing}
                    onChange={(val: any) => {
                      setFieldValue('sizing', val);
                      setFieldTouched('sizing');
                    }}
                    label={t('MEASUREMENT.SKIP.SIZE')}
                    labelWidth={30}
                  >
                    {sizing.sizes.map((item: any) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <CustomSelect
                    id="sizeNumber"
                    name="sizeNumber"
                    value={values.sizeNumber}
                    onChange={(val: any) => {
                      setFieldValue('sizeNumber', val);
                      setFieldTouched('sizeNumber');
                    }}
                    label={t('MEASUREMENT.SKIP.SIZE_NUMBER')}
                    labelWidth={90}
                  >
                    {sizingNumberRow.map((item: any) => item)}
                  </CustomSelect>

                  {this.p.accountStore.account.extendedMeasurement && (
                    <div className={classes.backSizeContainer}>
                      <div
                        className={
                          isIE
                            ? classNames(classes.backSizeSelect, classes.backSizeContainer)
                            : classes.backSizeContainer
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.p.backSize}
                              className={classes.backSize}
                              onChange={this.p.handleBackCheckbox}
                              value="back"
                              color="primary"
                              disabled={!values.sizing || !values.sizeNumber}
                            />
                          }
                          label={t('MEASUREMENT.SELECT_BACK_SIZE')}
                        />
                        {this.p.backSize && (
                          <CustomSelect
                            id="backSize"
                            name="backSize"
                            value={values.backSize}
                            onChange={(val: any) => {
                              setFieldValue('backSize', val);
                              setFieldTouched('backSize');
                            }}
                            label={t('MEASUREMENT.BACK_SIZE')}
                            labelWidth={130}
                          >
                            {values.sizeNumber !== 1 && (
                              <MenuItem value={values.sizeNumber - 1}>{values.sizeNumber - 1}</MenuItem>
                            )}
                            <MenuItem value={values.sizeNumber}>{values.sizeNumber}</MenuItem>
                            {values.sizeNumber !== 9 && (
                              <MenuItem value={values.sizeNumber === 9 ? values.sizeNumber : values.sizeNumber + 1}>
                                {values.sizeNumber + 1}
                              </MenuItem>
                            )}
                          </CustomSelect>
                        )}
                      </div>
                    </div>
                  )}
                  {this.p.gender === 'FEMALE' && (
                    <div className={classes.breastSize}>
                      <InputLabel className={classes.label}>{t('MEASUREMENT.BREAST_SIZE')}</InputLabel>
                      <Field name="breastSize" className={classes.radioRow} component={RadioGroup}>
                        <div className={classes.breastContainer}>
                          <FormControlLabel
                            value="A-B"
                            control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                            label="A-B"
                            disabled={isSubmitting}
                          />
                          <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(1)} />
                        </div>
                        <div className={classes.breastContainer}>
                          <FormControlLabel
                            value="C-D"
                            control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                            label="C-D"
                            disabled={isSubmitting}
                          />
                          <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(2)} />
                        </div>
                        <div className={classes.breastContainer}>
                          <FormControlLabel
                            value="E+"
                            control={<Radio checkedIcon={<Check />} disabled={isSubmitting} />}
                            label="E+"
                            disabled={isSubmitting}
                          />
                          <InfoIcon style={{ cursor: 'pointer' }} onClick={() => this.show(3)} />
                        </div>
                        <Rodal
                          visible={this.state.visible}
                          onClose={this.hide}
                          width={500}
                          height={450}
                          customStyles={{
                            backgroundColor: COLORS.DIALOG_COLOR,
                            color: '#ffffff'
                          }}
                        >
                          <div>
                            <img className={classes.breastPopUp} alt='breastIcon' src={this.getBreast()} />
                          </div>
                        </Rodal>
                      </Field>
                    </div>
                  )}
                  <Field
                    id="remark"
                    name="remark"
                    label={t('MEASUREMENT.REMARK')}
                    required
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                        root: classes.formControl
                      },
                      multiline: true
                    }}
                    component={TextField}
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    variant="outlined"
                  />
                </div>
                <div className={classes.btnContainer}>
                  <div className={classes.leftButton}>
                    <Button onClick={this.p.handleCancel} className={classes.button}>
                      {t('CANCEL')}
                    </Button>
                  </div>
                  <div className={classes.rightButton}>
                    <Button onClick={this.p.handleBackAfterSkip} className={classes.button}>
                      {t('BACK')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        !values.sizeNumber || !values.sizing || (this.p.gender === 'FEMALE' && !values.breastSize)
                      }
                      className={classes.buttonNext}
                    >
                      {t('NEXT')}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      color: '#fff',
      position: 'absolute',
      backgroundColor: COLORS.PRIMARY_DARK,
      bottom: 20,
      right: -30
    },

    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    textField: {
      width: '100%',
      maxWidth: 380
    },

    btnContainerIe: {
      width: '40%',
      bottom: 40,
      position: 'fixed',
      textAlign: 'right'
    },

    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 372px)',
      position: 'fixed',
      bottom: 40,
      '@media (max-width:1200px)': {
        padding: 20,
        position: 'relative',
        bottom: 0,
        width: '100%'
      }
    },

    endUserInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },

    endUserForm: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: 'right',
      '@media (max-width:500px)': {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    buttonNext: {
      marginLeft: 20,
      width: '20%',
      '@media (max-width:1200px)': {
        marginLeft: 10
      }
    },

    breastContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    breastPopUp: {
      maxWidth: 500,
      width: '100%',
      height: 'auto'
    },

    selectBackSize: {
      textAlign: 'center'
    },

    backSizeContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(SkipMeasurement)));
