import { action, configure, observable } from 'mobx';
import measurementService from '../services/measurementService';
import { Group } from './groupStore';
import { Project } from './projectStore';
import { Location } from './locationStore';
import {
  MeasurementFormFemale,
  MeasurementFormMale,
  MeasurementFormPartialFemale,
  MeasurementFormPartialMale,
  SuggestionFemale,
  SuggestionMale
} from './models/measurements.model';
import { AddMeasurementState } from '../components/measurements/MeasurementAdd';
import moment from 'moment';

configure({ enforceActions: 'observed' });

export type Measurement = {
  id: string;
  person: {
    gender?: string;
    firstName?: string;
    lastName?: string;
    pid?: string;
    id?: string;
  };
  createdBy: {
    username?: string;
  };
  remark?: string;
  project: Project;
  group: Group;
  location: Location;
  chestHeight?: any;
  chestWidth?: any;
  chosenId?: string;
  chosenLabel?: string;
  height?: any;
  suggestedLabel?: string;
  v?: string;
  waist?: any;
  backChosenId?: string;
  backChosenLabel?: string;
  breastsLabel?: string;
  breastsUnder?: any;
  suggestedBreastsLabel?: string;
  createdDate?: string;
};

export type Sizing = {
  id: string;
  sizes: string[];
  lengthMaximal: string;
  lengthMinimal: string;
  type: string;
};

export interface Suggestion {
  recommended: string;
  matrix: string[][];
  suggestions: string[];
  kind: 'MALE_VEST' | 'FEMALE_VEST';
  breastRecommended?: 'A-B' | 'C-D' | 'E+';
}

export default class MeasurementStore {
  // STORE
  @observable
  measurement: Measurement = {
    id: '',
    person: {
      gender: '',
      firstName: '',
      lastName: '',
      pid: ''
    },
    group: {
      active: true,
      id: '',
      name: '',
      locations: []
    },
    location: {
      active: true,
      id: '',
      name: ''
    },
    project: {
      groups: [],
      id: '',
      locations: [],
      name: '',
      type: ''
    },
    createdBy: {
      username: ''
    },
    remark: '',
    chestHeight: '',
    chestWidth: '',
    chosenId: '',
    chosenLabel: '',
    height: '',
    suggestedLabel: '',
    v: '',
    waist: '',
    backChosenId: '',
    backChosenLabel: '',
    breastsLabel: '',
    breastsUnder: '',
    suggestedBreastsLabel: '',
    createdDate: ''
  };

  @observable pending: boolean = false;

  @observable measurementList: Measurement[] = [];

  @observable measurementsTotalCount?: number;
  @observable
  suggestion: Suggestion = {
    recommended: '',
    matrix: [],
    suggestions: [],
    kind: 'MALE_VEST'
  };

  @observable sizing: Sizing = {
    id: '',
    sizes: [],
    lengthMaximal: '',
    lengthMinimal: '',
    type: ''
  };
  // ACTIONS

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setSuggestion = (suggestion: Suggestion) => {
    this.suggestion = suggestion;
  };

  @action
  private setSizes = (sizing: Sizing) => {
    this.sizing = sizing;
  };

  // ACTIONS

  @action
  private setMeasurement = (measurement: Measurement) => {
    this.measurement = {
      id: measurement && measurement.id,
      person: {
        gender: measurement.person.gender,
        firstName: measurement.person.firstName,
        lastName: measurement.person.lastName,
        pid: measurement.person.pid
      },
      group: {
        active: measurement.group.active,
        id: measurement.group.id,
        name: measurement.group.name,
        locations: measurement.group.locations
      },
      location: {
        active: measurement.location.active,
        id: measurement.location.id,
        name: measurement.location.name
      },
      project: {
        groups: measurement.project.groups,
        id: measurement.project.id,
        locations: measurement.project.locations,
        name: measurement.project.name,
        type: measurement.project.type
      },
      createdBy: {
        username: measurement.createdBy.username
      },
      chestHeight: measurement && measurement.chestHeight / 100,
      chestWidth: measurement && measurement.chestWidth / 100,
      chosenId: measurement && measurement.chosenId,
      chosenLabel: measurement && measurement.chosenLabel,
      height: measurement && measurement.height / 100,
      suggestedLabel: measurement && measurement.suggestedLabel,
      v: measurement && measurement.v,
      waist: measurement && measurement.waist / 100,
      backChosenId: measurement && measurement.backChosenId,
      backChosenLabel: measurement && measurement.backChosenLabel,
      breastsLabel: measurement && measurement.breastsLabel,
      breastsUnder: measurement && measurement.breastsUnder && measurement.breastsUnder / 100,
      suggestedBreastsLabel: measurement && measurement.suggestedBreastsLabel,
      createdDate: measurement && moment(measurement.createdDate).format('DD/MM/YYYY HH:MM'),
      remark: measurement && measurement.remark
    };
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.measurementsTotalCount = totalCount;
  };

  @action
  setMeasurementList = (measurementList: Measurement[]) => {
    this.measurementList = measurementList.map((measurement: Measurement) => ({
      id: measurement && measurement.id,
      person: {
        gender: measurement.person.gender,
        firstName: measurement.person.firstName,
        lastName: measurement.person.lastName,
        pid: measurement.person.pid
      },
      group: {
        active: measurement.group.active,
        id: measurement.group.id,
        name: measurement.group.name,
        locations: measurement.group.locations
      },
      location: {
        active: measurement.location.active,
        id: measurement.location.id,
        name: measurement.location.name
      },
      project: {
        groups: measurement.project.groups,
        id: measurement.project.id,
        locations: measurement.project.locations,
        name: measurement.project.name,
        type: measurement.project.type
      },
      createdBy: {
        username: measurement.createdBy.username
      },
      chestHeight: measurement && measurement.chestHeight / 100,
      chestWidth: measurement && measurement.chestWidth / 100,
      chosenId: measurement && measurement.chosenId,
      chosenLabel: measurement && measurement.chosenLabel,
      height: measurement && measurement.height / 100,
      suggestedLabel: measurement && measurement.suggestedLabel,
      v: measurement && measurement.v,
      waist: measurement && measurement.waist / 100,
      backChosenId: measurement && measurement.backChosenId,
      backChosenLabel: measurement && measurement.backChosenLabel,
      breastsLabel: measurement && measurement.breastsLabel,
      breastsUnder: measurement && measurement.breastsUnder && measurement.breastsUnder / 100,
      suggestedBreastsLabel: measurement && measurement.suggestedBreastsLabel,
      createdDate: measurement && moment(measurement.createdDate).format('DD/MM/YYYY HH:MM'),
      remark: measurement && measurement.remark
    }));
  };

  @action
  resetMeasurement = () => {
    this.setMeasurement({
      id: '',
      person: {
        gender: '',
        firstName: '',
        lastName: '',
        pid: ''
      },
      group: {
        active: true,
        id: '',
        name: '',
        locations: []
      },
      location: {
        active: true,
        id: '',
        name: ''
      },
      project: {
        groups: [],
        id: '',
        locations: [],
        name: '',
        type: ''
      },
      createdBy: {
        username: ''
      },
      remark: '',
      chestHeight: '',
      chestWidth: '',
      chosenId: '',
      chosenLabel: '',
      height: '',
      suggestedLabel: '',
      v: '',
      waist: '',
      backChosenId: '',
      backChosenLabel: '',
      breastsLabel: '',
      breastsUnder: '',
      suggestedBreastsLabel: ''
    });
  };

  @action
  getMeasurementListAction = async (params?: any) => {
    this.pendingStart();
    const result = await measurementService.getMeasurements(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setMeasurementList(result.measurements);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  getEndUserMeasurementListAction = async (id: string, params?: any) => {
    this.pendingStart();
    const result = await measurementService.getEndUserMeasurements(id, params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setMeasurementList(result.measurements);
    this.setTotalCount(result.totalCount);
    return true;
  };

  @action
  addMeasurementAction = async (data: AddMeasurementState) => {
    this.pendingStart();
    let params: MeasurementFormMale | MeasurementFormFemale | MeasurementFormPartialMale | MeasurementFormPartialFemale;

    if (!data.sizesStepForm.sizing) {
      if (data.userStep.gender === 'MALE') {
        params = new MeasurementFormMale(data);
      } else {
        params = new MeasurementFormFemale(data);
      }
    } else {
      if (data.userStep.gender === 'MALE') {
        params = new MeasurementFormPartialMale(data);
      } else {
        params = new MeasurementFormPartialFemale(data);
      }
    }

    const result = await measurementService.addMeasurement(params, data.endUserId);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    // this.getMeasurementListAction();
    return result.measurement.id;
  };
  @action
  getMeasurementByIdAction = async (id: string) => {
    this.pendingStart();
    const result = await measurementService.getMeasurementById(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setMeasurement(result.measurement);
    return result.measurement;
  };
  //
  // @action
  // editMeasurementAction = async (params:any, id:string) => {
  //     this.pendingStart();
  //     const result = await MeasurementService.editMeasurement(params, id);
  //     this.pendingEnd();
  //     if (result.error) {
  //         return false;
  //     }
  //     return id;
  // };
  //
  @action
  deleteMeasurementAction = async (id: string) => {
    this.pendingStart();
    const result = await measurementService.deleteMeasurement(id);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };
  @action
  getSuggestionsAction = async (data: any) => {
    this.pendingStart();
    let params: SuggestionMale | SuggestionFemale;
    if (data.gender === 'MALE') {
      params = new SuggestionMale(data);
    } else {
      params = new SuggestionFemale(data);
    }
    const result = await measurementService.getSuggestions(params);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setSuggestion(result.suggestion);
    return result.suggestion;
  };

  @action
  getSizeMatrix = async (gender: any) => {
    this.pendingStart();
    const result = await measurementService.getSizing(gender);
    this.pendingEnd();
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setSizes(result.sizing);
    return result.sizing;
  };
}
