import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { StepContent } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StepButton from '@material-ui/core/StepButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1),
      textAlign: 'right'
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    transparent: {
      background: 'transparent'
    },
    btnContainer: {
      textAlign: 'right'
    }
  })
);

function getSteps() {
  return ['User ID', 'Measurements', 'Size Selection', 'Customization', 'Summary'];
}

function StepperComponent(props: any) {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1200px)');
  const steps = getSteps();
  const { activeStep } = props;

  function getStepContent(step: number) {
    return props.steps[step];
  }

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear={true}
        className={classes.transparent}
        activeStep={activeStep}
        orientation={matches ? 'vertical' : 'horizontal'}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton disabled={activeStep < index} onClick={props.handleStep(index)} {...labelProps}>
                {label}
              </StepButton>
              {matches ? (
                <StepContent>
                  <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                </StepContent>
              ) : null}
            </Step>
          );
        })}
      </Stepper>
      <div>
        {!matches && (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepperComponent;
