import * as Yup from 'yup';
import i18n from '../config/i18n';

export const EmailValidator = Yup.string()
  .email(() => i18n.t('ERRORS.EMAIL'))
  .required(() => i18n.t('ERRORS.EMAIL_REQUIRED'));

export const PasswordValidator = Yup.string()
  .min(8, () => i18n.t('ERRORS.PASSWORD_MIN_LENGTH'))
  .test(
    'passwordPattern',
    () => i18n.t('ERRORS.PASSWORD'),
    (value: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)
  )
  .required(() => i18n.t('PROFILE.PASSWORD_REQUIRED'));

export const PasswordLoginValidator = Yup.string().required(() => i18n.t('PROFILE.PASSWORD_REQUIRED'));

export const SuggestedDateValidator = Yup.string().required(() => i18n.t('ORDER_FORM.SUGGESTED_DATE_REQUIRED'));

export const StringValidator = Yup.string()
  .required(() => i18n.t('ERRORS.FIELD_EMPTY'))
  .nullable();

export const NumberValidator = Yup.number().required(() => i18n.t('ERRORS.FIELD_EMPTY'));

export const PhoneValidator = Yup.string()
  .min(9, () => i18n.t('ERRORS.PHONE_MIN_LENGTH'))
  .test(
    'phonePattern',
    () => i18n.t('ERRORS.INVALID_SIGN'),
    (value: string) => /^[+]?[\d]{9,}$/.test(value)
  )
  .required(() => i18n.t('ERRORS.FIELD_EMPTY'));

export const MeasurementValidator = (min: number, max: number) => {
  return Yup.number()
    .min(min, 'Minimum value is ' + min)
    .max(max, 'Maximum value is ' + max)
    .required(() => i18n.t('ERRORS.FIELD_EMPTY'))
    .test(
      'measurementPattern',
      () => i18n.t('ERRORS.INVALID_FORMAT'),
      (value: string) => /^(?!0\d|$)\d*([.]\d{1,3})?$/.test(value)
    )
    .typeError('Value must be a number');
};
