import { action, configure, observable, toJS } from 'mobx';
import moment from 'moment';
import { Group } from './groupStore';
import { Measurement } from './measurementStore';
import { Project } from './projectStore';
import cartService from '../services/cartService';

configure({ enforceActions: 'observed' });

export type Cart = {
  id: string;
  firstName: string;
  lastName: string;
  pid: string;
  gender: string;
  group: Group;
  location: Location;
  measurement: Measurement;
  project: Project;
};

export default class CartStore {
  // STORE

  @observable pending: boolean = false;

  @observable cartList: Cart[] = [];

  @observable cart: Cart[] = [];

  @observable filteredCart: Cart[] = [];

  @observable cartTotalCount?: number;

  // ACTIONS

  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setTotalCount = (totalCount: number) => {
    this.cartTotalCount = totalCount;
  };

  @action
  private setCartList = (cartList: Cart[]) => {
    this.cartList = cartList;
  };

  @action
  getCartListAction = async (params?: any) => {
    if (params && params.pid) {
      this.filteredCart = toJS(this.cart).filter((item: any) => {
        return item.pid.includes(params.pid);
      });
    } else {
      this.filteredCart = this.cart;
    }
    this.setTotalCount(this.filteredCart.length);
  };

  @action
  getCartEndUserFromLocaleStorage = () => {
    let CartValid = localStorage.cartValid && JSON.parse(localStorage.cartValid);
    let now = moment().format('x');
    if (CartValid < now) {
      localStorage.clear();
      this.cart = [];
    }
    let parsedCart = localStorage.cart && JSON.parse(localStorage.cart);
    if (parsedCart) {
      this.cart = parsedCart;
      this.setTotalCount(this.filteredCart.length);
    }
    return true;
  };

  @action
  addEndUserToCartAction = (newEndUser: any) => {
    let cartValid = moment()
      .add(1, 'd')
      .format('x');
    this.pendingStart();
    this.cart = [...this.cart, newEndUser];
    JSON.stringify(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart));
    localStorage.setItem('cartValid', JSON.stringify(cartValid));
    this.pendingEnd();
  };

  @action
  removeEndUserFromCartAction = (newEndUser: any) => {
    this.pendingStart();
    let index = this.cart.findIndex(item => item.id === newEndUser.id);
    this.cart.splice(index, 1);
    localStorage.setItem('cart', JSON.stringify(this.cart));
    this.setTotalCount(JSON.parse(localStorage.cart).length);
    if (JSON.parse(localStorage.cart).length === 0) {
      localStorage.clear();
    }
    this.pendingEnd();
  };

  @action
  sendCartAction = async (data: any) => {
    this.pendingStart();
    const initResult = await cartService.sendCart(data);
    if (!initResult || initResult.error) {
      return false;
    } else {
      let interval: any;

      interval = setInterval(async () => {
        const result = await cartService.getOrderStatus(initResult.task.id);
        if (result.task.state==='SUCCESS') {
          const file = await cartService.getOrderInfo(initResult.task.id);
          clearInterval(interval);
          this.saveData(file);
        }
      }, 2000);
    }
    this.pendingEnd();

    return true;
  };

  saveData = (blob: any) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    // @ts-ignore
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'order.xlsx';
    a.click();
    a.remove();
  };
}
