import REST from '../constants/rest';
import API from './apiService';
import { API_URL } from '../config/api';

export default class CartService {
  static async sendCart(data: any) {
    const path = '/orders';
    const options = {
      method: REST.POST,
      body: {
        persons: data
      }
    };
    return API.fetch(path, options);
  }

  static async getOrderInfo(data: any) {
    const path = `${API_URL}/orders/${data}/content`;
    return fetch(path, { credentials: 'include' }).then((response: any) => {
      if (response.status === 200 || response.status === 0) {
        return response.blob();
      } else {
        return { error: 'Cannot get file' };
      }
    });
  }

  static getOrderStatus(data: any) {
    const path = `/orders/${data}`;
    const options = {
      method: REST.GET,
    };
    return API.fetch(path, options);
  }
}
