import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Theme } from '@material-ui/core';

import { withSnackbar } from 'notistack';
import createStyles from '@material-ui/core/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccountStore from '../../stores/accountStore';
import { InjectedNotistackProps } from 'notistack';
import PasswordChange from './PasswordChange';
import ProfileEdit from './ProfileEdit';
import { RouteComponentProps } from 'react-router';

interface MatchParams {}

interface AccountProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends AccountProps, RouteComponentProps<MatchParams> {
  accountStore: AccountStore;
  classes: any;
  userId: string;
  location: any;
}

@inject('accountStore')
@observer
class Profile extends Component<AccountProps> {
  get p() {
    return this.props as InjectedProps;
  }

  render() {
    const { classes } = this.p;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <Card className={classes.cardContainer}>
            <CardContent>
              <ProfileEdit />
              <PasswordChange />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      backgroundSize: 'cover'
    },

    content: {
      width: '100%',
      padding: 20,

      '@media (max-width:1200px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },

    cardContainer: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
      boxShadow: 'none'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(Profile)));
