import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Theme } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { COLORS } from '../../styles/colors';
import createStyles from '@material-ui/core/styles/createStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StepperComponent from './StepperComponent';
import MeasurementsStep, { MeasurementsStepForm } from './steps/MeasurementsStep';
import CustomizationStep from './steps/CustomizationStep';
import UserStep, { UserStepForm } from './steps/UserStep';
import SizeSelectionStep, { SizeSelectionStepForm } from './steps/SizeSelectionStep';
import MeasurementStore from '../../stores/measurementStore';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import SummaryStep from './steps/SummaryStep';
import { MeasurementFormFemale, MeasurementFormMale } from '../../stores/models/measurements.model';
import { DialogComponent } from '../DialogComponent';
import { SizesStepForm } from './steps/SkipMeasurement';

interface MatchParams {
  id: string;
}

interface AddMeasurementProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends AddMeasurementProps, RouteComponentProps<MatchParams> {
  measurementStore: MeasurementStore;
  classes: any;
  userId: string;
}

export interface AddMeasurementState {
  edit: boolean;
  activeStep: number;
  userStep: UserStepForm;
  measurementsStep: MeasurementsStepForm;
  sizeSelectionStep: SizeSelectionStepForm;
  sizesStepForm: SizesStepForm;
  customizationStep: { v: number };
  endUserId: string;
  openDialog: boolean;
  skipped: boolean;
}

const initialState = {
  edit: false,
  activeStep: 0,
  userStep: new UserStepForm(),
  measurementsStep: new MeasurementsStepForm(''),
  sizeSelectionStep: new SizeSelectionStepForm(),
  sizesStepForm: new SizesStepForm(''),
  customizationStep: { v: 0 },
  endUserId: '',
  openDialog: false,
  skipped: false
};

@inject('measurementStore')
@observer
class MeasurementAdd extends Component<AddMeasurementProps, AddMeasurementState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = { ...initialState, edit: !this.p.match.params.id };

  addMeasurement = async () => {
    const success = await this.p.measurementStore.addMeasurementAction(this.state);

    if (!success) {
      this.p.enqueueSnackbar(this.p.t('ERRORS.CANT_ADD_MEASUREMENT'), {
        variant: 'error'
      });
    } else {
      this.p.enqueueSnackbar(this.p.t('MEASUREMENT.ADD_SUCCESS'), {
        variant: 'success'
      });
      this.p.history.replace('/measurement/add');
    }
  };

  handleStep = (step: number) => () => {
    this.setState(prevState => ({ activeStep: step }));
  };

  handleChangeStep(howMuch: number, skipped: boolean = false) {
    this.setState(prevState => ({ activeStep: prevState.activeStep + howMuch, skipped }));
  }

  handleCancel = () => {
    this.setState(initialState);
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  render() {
    const { classes, t } = this.p;
    const { activeStep } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <Card className={classNames(classes.card, classes.cardContainer)}>
            <CardContent className={classes.cardContent}>
              <StepperComponent
                activeStep={activeStep}
                handleStep={this.handleStep}
                steps={[
                  <UserStep
                    skipMeasurement={(values: any, id: string) => {
                      this.handleChangeStep(2, true);
                      this.setState({ userStep: values, endUserId: id });
                    }}
                    handleNext={(values: any, id: string) => {
                      this.handleChangeStep(1, false);
                      this.setState({ userStep: values, endUserId: id });
                    }}
                    endUserDetailId={this.p.match.params.id}
                    edit={this.state.edit}
                    values={this.state.userStep}
                    endUserId={this.state.endUserId}
                  />,
                  <MeasurementsStep
                    handleNext={(values: MeasurementsStepForm) => {
                      this.handleChangeStep(1, false);
                      this.setState({ measurementsStep: values });
                    }}
                    values={this.state.measurementsStep}
                    handleBack={() => this.handleChangeStep(-1, false)}
                    handleCancel={this.handleClickOpenDialog}
                    gender={this.state.userStep && this.state.userStep.gender}
                  />,
                  <SizeSelectionStep
                    handleNext={(values: SizeSelectionStepForm) => {
                      this.handleChangeStep(1, false);
                      this.setState({ sizeSelectionStep: values });
                    }}
                    values={this.state.sizeSelectionStep}
                    valuesSkip={this.state.sizesStepForm}
                    handleBack={() => this.handleChangeStep(-1, false)}
                    handleBackAfterSkip={() => this.handleChangeStep(-2, true)}
                    handleCancel={this.handleClickOpenDialog}
                    gender={this.state.userStep && this.state.userStep.gender}
                    skipped={this.state.skipped}
                    handleNextAfterSkip={(values: SizesStepForm) => {
                      this.handleChangeStep(1, true);
                      this.setState({ sizesStepForm: values });
                    }}
                  />,
                  <CustomizationStep
                    handleNext={(values: { v: number }) => {
                      this.handleChangeStep(1, false);
                      this.setState({ customizationStep: values });
                      // this.addMeasurement();
                    }}
                    handleBack={() => this.handleChangeStep(-1, false)}
                    handleBackAfterSkip={() => this.handleChangeStep(-1, true)}
                    handleCancel={this.handleClickOpenDialog}
                    values={this.state.customizationStep}
                    skipped={this.state.skipped}
                    handleNextAfterSkip={(values: { v: number }) => {
                      this.handleChangeStep(1, true);
                      this.setState({ customizationStep: values });
                    }}
                  />,
                  <SummaryStep
                    values={
                      this.state.userStep && this.state.userStep.gender === 'MALE'
                        ? new MeasurementFormMale(this.state)
                        : new MeasurementFormFemale(this.state)
                    }
                    endUserId={this.state.endUserId}
                    handleCancel={this.handleClickOpenDialog}
                    handleNext={this.addMeasurement}
                    handleBack={() => this.handleChangeStep(-1, false)}
                    handleBackAfterSkip={() => this.handleChangeStep(-1, true)}
                    skipped={this.state.skipped}
                  />
                ]}
              />
            </CardContent>
          </Card>
        </div>
        <DialogComponent
          title={'MEASUREMENT.CLEAR_MEASUREMENT'}
          t={t}
          classes={classes}
          submitButtonTitle={'YES'}
          cancel={'NO'}
          handleClickCloseDialog={this.handleClickOpenDialog}
          handleDelete={this.handleCancel}
          open={this.state.openDialog}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      backgroundSize: 'cover'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      maxWidth: 380,
      marginTop: 12
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: COLORS.WHITE + '!important'
    },

    formControl: {
      background: 'rgba(255, 255, 255, 0.12)'
    },

    content: {
      width: '100%',
      padding: 20,

      '@media (max-width:1200px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },

    cardContainer: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
      boxShadow: 'none',
      overflow: 'visible'
    },

    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: 'none'
    },
    customSelect: {
      height: 56
    },
    select: {
      width: '80%',
      maxWidth: 380,
      height: 56,
      marginTop: 10,
      borderRadius: 5,
      borderColor: COLORS.WHITE,
      background: 'rgba(255, 255, 255, 0.12)'
    },

    btn: {
      position: 'absolute',
      bottom: 20,
      right: 20
    },

    btnDelete: {
      position: 'absolute',
      bottom: 20,
      right: 20
    },

    btnEdit: {
      position: 'absolute',
      bottom: 20,
      right: 90
    },

    btnColor: {
      color: COLORS.WHITE,
      '&:hover': {
        background: COLORS.PRIMARY_DARK
      }
    },

    stepperContainer: {
      display: 'flex',
      flexDirection: 'row'
    },

    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    measurementsStepContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '33%'
    },

    textFieldMeasurementsStep: {
      marginRight: theme.spacing(1),
      width: '80%',
      maxWidth: 380,
      marginTop: 12
    },

    avatarContainer: {
      width: '33%',
      display: 'flex'
    },

    avatarInfoContainer: {
      width: '33%',
      display: 'flex',
      flexDirection: 'column'
    },

    customizationStepContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '33%',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(MeasurementAdd)));
