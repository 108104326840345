import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import createStyles from '@material-ui/core/styles/createStyles';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import EndUserStore from '../../../stores/endUserStore';
import { MeasurementFormFemale, MeasurementFormMale } from '../../../stores/models/measurements.model';
import MeasurementSummary from '../MeasurementSummary';

interface MatchParams {
  id: string;
}

interface SummaryStepProps extends WithTranslation, InjectedNotistackProps {
  handleNext: (event: any) => void;
  handleBack: () => void;
  handleBackAfterSkip: () => void;
  handleCancel: () => void;
  values: MeasurementFormMale | MeasurementFormFemale;
  endUserId: string;
  skipped: boolean;
}

interface InjectedProps extends SummaryStepProps, RouteComponentProps<MatchParams> {
  endUserStore: EndUserStore;
  classes: any;
}

interface SummaryStepState {}

@inject('endUserStore')
@observer
class SummaryStep extends Component<SummaryStepProps, SummaryStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  submit = () => {
    this.p.handleNext(this.p.skipped);
  };

  componentDidMount(): void {
    this.getEndUserById();
  }

  getEndUserById = async () => {
    if (this.p.endUserId) {
      const result = await this.p.endUserStore.getEndUserByIdAction(this.p.endUserId);
      if (result) {
        this.setState({ endUser: result });
      }
    }
  };
  render() {
    const {
      classes,
      t,
      values,
      endUserStore: { endUser }
    } = this.p;

    return (
      <>
        <div className={classes.stepContainer}>
          <MeasurementSummary variant="summary" values={values} endUser={endUser} skipped={this.p.skipped} />
        </div>
        <div className={classes.btnContainer}>
          <div className={classes.leftButton}>
            <Button onClick={this.p.handleCancel} className={classes.button}>
              {t('CANCEL')}
            </Button>
          </div>
          <div className={classes.rightButton}>
            <Button
              onClick={this.p.skipped ? this.p.handleBackAfterSkip : this.p.handleBack}
              className={classes.button}
            >
              {t('BACK')}
            </Button>
            <Button variant="contained" color="primary" onClick={this.p.handleNext} className={classes.buttonNext}>
              {t('SUBMIT')}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      '@media (max-width:500px)': {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    subtitle: {
      marginLeft: '-10px !important'
    },
    divider: {
      marginBottom: 15,
      marginLeft: '-10px !important'
    },
    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 'calc(100% - 372px)',
      position: 'fixed',
      bottom: 40,
      '@media (max-width:1200px)': {
        padding: 20,
        position: 'relative',
        bottom: 0,
        width: '100%'
      }
    },

    leftButton: {
      flex: 1
    },

    rightButton: {
      flex: 1,
      textAlign: 'right',
      '@media (max-width:500px)': {
        display: 'flex',
        flexDirection: 'row'
      }
    },

    button: {
      width: '20%'
    },

    buttonNext: {
      marginLeft: 20,
      width: '20%',
      '@media (max-width:1200px)': {
        marginLeft: 10
      }
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(SummaryStep)));
