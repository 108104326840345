import { toJS } from 'mobx';
import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../config/api';
import { stores } from '../App';
import REST from '../constants/rest';
import { FetchItOptions } from '../models/apiModels';
import { sentryErrorSend } from '../utils/sentryError';

axios.defaults.withCredentials = true;

async function fetchIt(url: string, options: FetchItOptions) {
  const baseURL = stores.authStore.baseUrl;
  const urlsWithoutProject = ['/users/profile', '/projects/', '/suggestion', '/sizing', 'orders'];

  const request: AxiosRequestConfig = {
    method: options.method,
    url: urlsWithoutProject.findIndex((item: string) => url.includes(item)) > -1 ? API_URL + url : baseURL + url,
    data: options.body
  };
  if (toJS(stores.authStore.session) && request.method !== REST.GET) {
    request.headers = {
      'Content-Type': 'application/json',
      'X-Xsrftoken': toJS(stores.authStore.session).xsrfToken
    };
  } else {
    request.headers = {
      'Content-Type': 'application/json'
    };
  }
  let response;

  try {
    response = await axios(request);

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        stores.authStore.logoutAction();
        stores.accountStore.clearAccountAction();
      } else if (error.response.status >= 400) {
        sentryErrorSend(request, error);
      }
      return { error: error.response.data, status: error.response.status };
    }
    return { error: 'error' };
  }
}

export default {
  fetch: fetchIt
};
