import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Theme, withStyles } from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import AppStore from '../stores/appStore';
import AuthStore from '../stores/authStore';
import { COLORS } from '../styles/colors';
import createStyles from '@material-ui/core/styles/createStyles';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { WithTranslation, withTranslation } from 'react-i18next';
import AccountStore from '../stores/accountStore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { HomeRouting } from '../models/routing';
import { RouteComponentProps } from 'react-router';
import Logo from '../assets/images/logo_gray_icon.svg';
import EndUsersList from './endUsers/EndUsersList';
import EndUserDetails from './endUsers/EndUserDetails';
import MeasurementDetails from './measurements/MeasurementDetails';
import Profile from './profile/Profile';
import IconButton from '@material-ui/core/IconButton';
import LogoMaio from '../assets/maio_logo.svg';
import MenuIcon from '@material-ui/icons/Menu';
import CloseMenu from '@material-ui/icons/Close';
import MeasurementAdd from './measurements/MeasurementAdd';
import CartStore  from '../stores/cartStore';
import CartList from './cart/CartList';
const { version } = require('../../package.json');

interface HomeContainerProps extends WithTranslation {}

interface InjectedProps extends HomeContainerProps, RouteComponentProps {
  authStore: AuthStore;
  appStore: AppStore;
  accountStore: AccountStore;
  cartStore: CartStore;
  classes: any;
}

interface HomeContainerState {
  open: boolean;
}

@inject('authStore', 'appStore', 'accountStore', 'cartStore')
@observer
class HomeContainer extends Component<HomeContainerProps, HomeContainerState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    open: true
  };

  componentDidMount(): void {
    this.getProfile();
    this.getCart();
  }

  getProfile = async () => {
    const success = await this.p.accountStore.getAccountAction();
    if (!success) {
      return this.p.history.replace('/profile');
    }
  };

  getCart = async () => {
    const success = await this.p.cartStore.getCartEndUserFromLocaleStorage();
    if (!success) {
      return this.p.history.replace('/end-users');
    }
  };

  logout = () => {
    this.p.authStore.logoutAction();
    this.p.accountStore.clearAccountAction();
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, location, t } = this.p;

    return (
      <div className={classes.root}>
        <Drawer
          className={!this.state.open ? classes.drawer : classes.drawerNone}
          variant="permanent"
          classes={{
            paper: !this.state.open ? classes.drawerPaper : classes.drawerPaperNone
          }}
          anchor="left"
        >
          {!this.state.open && <CloseMenu className={classes.closeMenu} onClick={this.handleDrawerToggle} />}
          <div className={classes.toolbar}>
            <img className={classes.logo} src={Logo} alt="Logo" />
          </div>
          <Divider />
          <List>
            {HomeRouting.map((route, key) => (
              <NavLink
                className={classes.navItem}
                key={route.path}
                to={route.path}
                onClick={this.handleDrawerToggle}
                data-cy={route.name}
              >
                <ListItem component={'span'} className={classes.menuItem} button>
                  <ListItemIcon>{route.icon && route.icon()}</ListItemIcon>
                  <ListItemText className={classes.menuItem} primary={route.name} />
                </ListItem>
              </NavLink>
            ))}
            <ListItem component={'span'} className={classes.menuItem} button onClick={this.logout} data-cy="logoutBtn">
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText className={classes.menuItem} primary={t('LOGOUT')} />
            </ListItem>
          </List>
          <Divider />
          <div className={classes.logoCompanyContainer}>
            <span>{version} - Powered by</span>
            <img className={classes.logoCompany} src={LogoMaio} alt="Logo" />
          </div>
        </Drawer>
        <main className={classes.content}>
          {this.state.open && (
            <IconButton
              color="inherit"
              className={classes.mobileMenu}
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <TransitionGroup>
            {/*<CSSTransition key={location.key} classNames="fade" timeout={0}>*/}
            <Switch location={location}>
              <Route exact path="/end-users" component={EndUsersList} />
              <Route exact path="/end-users/details/:id" component={EndUserDetails} />
              <Route path="/end-users/add" component={EndUserDetails} />
              <Route path="/measurements/add" component={MeasurementAdd} />
              <Route path="/measurements/details/:id" component={MeasurementDetails} />
              <Route path="/end-users/:id/measurements/add" component={MeasurementAdd} />
              <Route path="/cart" component={CartList} />
              {/*<Route path="/manual" component={Manual} />*/}
              <Route path="/profile" component={Profile} />
              <Redirect to="/end-users" />
              {/*<Redirect to="/" />*/}
            </Switch>
            {/*</CSSTransition>*/}
          </TransitionGroup>
        </main>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '@media (max-width:730px)': {
        padding: 20
      }
    },
    drawerNone: {
      flexShrink: 0,
      width: 0,
      transition: '0.5s width',
      '@media (min-width:1130px)': {
        width: 240
      }
    },

    drawer: {
      flexShrink: 0,
      display: 'block',
      width: 240,
      transition: '0.5s width',
      '@media (max-width:730px)': {
        width: 0
      }
    },

    drawerPaperNone: {
      width: 0,
      transition: '0.5s width',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1)),#424242 no-repeat fixed center center;',
      overflowX: 'hidden',
      overflowY: 'auto',
      '@media (min-width:1130px)': {
        width: 240
      }
    },

    drawerPaper: {
      transition: '0.5s width',
      width: 240,
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1)),#424242 no-repeat fixed center center;',
      overflowX: 'hidden',
      overflowY: 'auto',
      '@media (max-width:730px)': {
        width: '100%'
      }
    },
    navItem: {
      textDecoration: 'none',
      '&.activeNav > span': {
        background: COLORS.PRIMARY_DARK
      }
    },

    test: { color: COLORS.GOLD },
    toolbar: {
      padding: 12,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 65
    },
    content: {
      flex: 1,
      width: '100%',
      '@media (min-width:1131px)': {
        padding: 90,
        paddingBottom: 0
      },
      '@media (min-width:731px)': {
        padding: 20,
        overflow: 'auto',
        height: 'calc(100vh - 40px)',
        overflowX: 'hidden'
      }
    },
    menuItem: {
      margin: theme.spacing(1, 0),
      color: COLORS.WHITE,
      '& span': {
        fontFamily: '"RbFontLight",serif !important'
      }
    },

    mobileMenu: {
      color: COLORS.WHITE,
      cursor: 'pointer',
      display: 'none',
      '@media (max-width:1130px)': {
        display: 'block'
      }
    },

    closeMenu: {
      display: 'none',
      '@media (max-width:1130px)': {
        display: 'block',
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer'
      }
    },

    logoCompany: {
      marginLeft: 5,
      maxWidth: 50,
      width: '100%',
      height: 'auto'
    },

    logoCompanyContainer: {
      marginTop: 50,
      textAlign: 'right',
      marginRight: 20,
      marginBottom: 20
    }
  });

export default withStyles(styles)(withTranslation()(HomeContainer));
