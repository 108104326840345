import { action, computed, configure, observable } from 'mobx';
import { UserCredentials } from './models/authStore.model';
import accountService from '../services/accountService';
import { API_URL } from '../config/api';

configure({ enforceActions: 'observed' });

export default class AuthStore {
  // STORE
  @observable session: any;
  @observable pending: boolean = false;
  @observable activeProject: string = '';
  @observable projectUrl: string = '';

  @action
  private setSession = (value: any) => {
    this.session = value;
  };

  //FUNCTIONS
  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  setActiveProjectAction = (projectId: any) => {
    this.activeProject = projectId;
    sessionStorage.setItem('@projectNFM', JSON.stringify(projectId));
  };

  // ACTIONS
  @action
  loginAction = async (credentials: UserCredentials) => {
    this.pendingStart();
    const result = await accountService.login(credentials);
    this.pendingEnd();
    if (result.error && result.status === 403) {
      this.logoutAction();
      return { error: true, accessDenied: true };
    }
    if (result.error) {
      this.logoutAction();
      return { error: true, accessDenied: false };
    }
    const session = {
      uuid: result.session.userId,
      userType: result.session.userType,
      xsrfToken: result.session.xsrfToken,
      extendedMeasurement: result.session.extendedMeasurement
    };
    this.setSession(session);
    sessionStorage.setItem('@sessionNFM', JSON.stringify(session));
    return result;
  };

  @computed
  get baseUrl() {
    if (this.activeProject) {
      return API_URL + `/projects/${this.activeProject}`;
    } else {
      return API_URL;
    }
  }

  @action
  autoLoginAction = () => {
    this.session = AuthStore.getSession();
    this.activeProject = AuthStore.getProject();
  };

  @action
  logoutAction = async () => {
    this.pendingStart();
    if (this.session && this.session.xsrfToken) {
      await accountService.logout();
      this.setActiveProjectAction('');
    }
    this.setSession(null);
    sessionStorage.removeItem('@sessionNFM');
    sessionStorage.removeItem('@projectNFM');
    this.pendingEnd();
  };

  @action
  getProjectsAction = async () => {
    this.pendingStart();
    const result = await accountService.getProject();
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    if (result.projects.length === 1) {
      this.setActiveProjectAction(result.projects[0].id);
    }
    return result.projects;
  };

  // UTILS

  static getProject() {
    const project = sessionStorage.getItem('@projectNFM');
    if (project) {
      return JSON.parse(project);
    }
  }

  static getSession() {
    const session = sessionStorage.getItem('@sessionNFM');
    if (session) {
      return JSON.parse(session);
    }
  }
}
