import { configure, observable, action } from 'mobx';
import i18n from '../config/i18n';

configure({ enforceActions: 'observed' });

export default class AppStore {
  // STORE
  @observable locale!: string;

  @observable activeTab!: string;

  @observable drawer: boolean = true;

  @observable smallScreen: boolean = false;

  @observable initialized: boolean = true;

  @observable isFilter: boolean = false;
  // ACTIONS

  @action
  filterStart = () => {
    this.isFilter = true;
  };

  @action
  filterEnd = () => {
    this.isFilter = false;
  };

  @action
  setLocaleAction(locale: string, langPickerView = false) {
    localStorage.setItem('@SFLocale', locale);
    i18n.changeLanguage(locale);
    this.locale = locale;
  }

  @action
  setDrawerAction(status: boolean) {
    this.drawer = status;
  }

  @action
  toggleDrawerAction() {
    this.drawer = !this.drawer;
  }

  @action
  setSmallScreenStateAction(isSmallScreen: boolean) {
    this.smallScreen = isSmallScreen;
  }

  @action
  updateTabTitleAction = (tabTitle: string) => {
    this.activeTab = tabTitle;
  };
}
