import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import createStyles from '@material-ui/core/styles/createStyles';
import { RouteComponentProps } from 'react-router';
import { MeasurementFormFemale } from '../../stores/models/measurements.model';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

interface MatchParams {
  id: string;
}

interface SummaryStepProps extends WithTranslation, InjectedNotistackProps {
  endUser: any;
  values: any;
  skipped: boolean;
  variant: 'summary' | 'details';
}

interface InjectedProps extends SummaryStepProps, RouteComponentProps<MatchParams> {
  classes: any;
}

interface SummaryStepState {}

class MeasurementSummary extends Component<SummaryStepProps, SummaryStepState> {
  get p() {
    return this.props as InjectedProps;
  }

  componentDidMount(): void {
    document.body.classList.add('summaryBackground');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('summaryBackground');
  }

  render() {
    const { classes, t, values, endUser } = this.p;
    let measurementValues;
    if (this.p.variant === 'summary') {
      measurementValues = {
        ...values,
        chestWidth: values.chestWidth / 100,
        breastsUnder: values.breastsUnder / 100,
        chestHeight: values.chestHeight / 100,
        height: values.height / 100,
        waist: values.waist / 100,
        remark: values.remark
      };
    } else {
      measurementValues = { ...values };
    }
    return (
      <>
        <div>
          <Typography className={classNames(classes.subtitle, 'title')} display="block" variant="subtitle1">
            {t('END_USER.TITLE')}
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="h4">
            {endUser.firstName} {endUser.lastName}
          </Typography>
          <Typography variant="caption">{t('END_USER.PROJECT')}</Typography>
          <Typography variant="h5">{endUser.project.name}</Typography>
          <Typography variant="caption">{t('END_USER.GROUP')}</Typography>
          <Typography variant="h5">{endUser.group.name}</Typography>
          <Typography variant="caption">{t('END_USER.LOCATION')}</Typography>
          <Typography variant="h5">{endUser.location.name} </Typography>
          <Typography variant="caption">{t(`MEASUREMENT.GENDER`)}</Typography>
          {endUser.gender && <Typography variant="h5">{t(`MEASUREMENT.${endUser.gender}`)}</Typography>}
        </div>
        <div>
          <Typography className={classNames(classes.subtitle, 'title')} display="block" variant="subtitle1">
            {t('MEASUREMENT.SUMMARY')}
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="caption">{t('MEASUREMENT.SELECTED_SIZE')}</Typography>
          <Typography variant="h6">{measurementValues.chosenLabel}</Typography>

          {/*{*/}
          {/*    !this.p.skipped &&*/}
          {/*    <>*/}
          {/*        <Typography variant="caption">*/}
          {/*            {t("END_USER_LIST.SUGGESTED_LABEL")}*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="h6">*/}
          {/*            {measurementValues.suggestedLabel ? measurementValues.suggestedLabel : '-'}*/}
          {/*        </Typography>*/}
          {/*    </>*/}
          {/*}*/}
          {endUser.gender === 'FEMALE' && (
            <>
              <Typography variant="caption">{t('MEASUREMENT.BREAST_SIZE')}</Typography>
              <Typography variant="h6">
                {(measurementValues as MeasurementFormFemale).breastsLabel
                  ? (measurementValues as MeasurementFormFemale).breastsLabel
                  : '-'}
              </Typography>
            </>
          )}

          {/*{*/}
          {/*    !this.p.skipped &&*/}
          {/*    endUser.gender === "FEMALE" && (*/}
          {/*        <>*/}
          {/*            <Typography variant="caption">*/}
          {/*                {t("END_USER_LIST.SUGGESTED_BREASTS_LABEL")}*/}
          {/*            </Typography>*/}
          {/*            <Typography variant="h6">*/}
          {/*                {measurementValues.suggestedBreastsLabel ? measurementValues.suggestedBreastsLabel : '-'}*/}
          {/*            </Typography>*/}
          {/*        </>*/}
          {/*    )*/}
          {/*}*/}

          <Typography variant="caption">{t('END_USER_LIST.V')}</Typography>
          <Typography variant="h6">{measurementValues.v}</Typography>

          {!this.p.skipped && (
            <>
              {endUser.gender === 'FEMALE' && (
                <>
                  <Typography variant="caption">{t('MEASUREMENT.BAND_CIRCUM')}</Typography>
                  <Typography variant="h6">
                    {(measurementValues as MeasurementFormFemale).breastsUnder
                      ? (measurementValues as MeasurementFormFemale).breastsUnder + 'cm'
                      : '-'}
                  </Typography>
                </>
              )}

              <Typography variant="caption">{t('END_USER_LIST.CHEST_WIDTH')}</Typography>
              <Typography variant="h6">
                {measurementValues.chestWidth ? measurementValues.chestWidth + 'cm' : '-'}
              </Typography>

              <Typography variant="caption">{t('MEASUREMENT.CHEST_HEIGHT')}</Typography>
              <Typography variant="h6">
                {measurementValues.chestHeight ? measurementValues.chestHeight + 'cm' : '-'}
              </Typography>

              <Typography variant="caption">{t('MEASUREMENT.WAIST')}</Typography>
              <Typography variant="h6">{measurementValues.waist ? measurementValues.waist + 'cm' : '-'}</Typography>

              <Typography variant="caption">{t('MEASUREMENT.BODY_HEIGHT')}</Typography>
              <Typography variant="h6">{measurementValues.height ? measurementValues.height + 'cm' : '-'}</Typography>
            </>
          )}

          {values.backChosenLabel && (
            <>
              <Typography variant="caption">{t('MEASUREMENT.SELECTED_BACK_SIZE')}</Typography>
              <Typography variant="h6">
                {measurementValues.backChosenLabel ? measurementValues.backChosenLabel : '-'}
              </Typography>
            </>
          )}

          {values.remark && (
            <>
              <Typography variant="caption">{t('MEASUREMENT.REMARK')}</Typography>
              <Typography className={classes.remark} variant="h6">
                {measurementValues.remark}
              </Typography>
            </>
          )}
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    stepContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
    subtitle: {
      marginLeft: '-10px !important'
    },
    divider: {
      marginBottom: 15,
      marginLeft: '-10px !important'
    },
    responsiveImg: {
      maxWidth: 350,
      marginTop: 70,
      width: '100%',
      height: 'auto'
    }
  });

export default withStyles(styles)(withTranslation()(withSnackbar(MeasurementSummary)));
