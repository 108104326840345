import i18n from '../config/i18n';
import MeasureIcon from '../assets/measure.png';
import EndUserIcon from '@material-ui/icons/FolderShared';
import UserIcon from '@material-ui/icons/Person';
import CartIcon from '@material-ui/icons/ShoppingCart';
import HelpIcon from '@material-ui/icons/HelpOutline';

import React, { ReactElement } from 'react';

interface Route {
  name: string;
  path: string;
}
interface HomeRoute extends Route {
  icon: () => ReactElement;
}

export const HomeRouting: HomeRoute[] = [
  {
    name: i18n.t('ROUTES.MEASUREMENTS'),
    path: '/measurements/add',
    icon: () => <img style={{ maxWidth: '25px', width: '100%', height: 'auto' }} alt='measureIcon' src={MeasureIcon} />
  },
  {
    name: i18n.t('ROUTES.END_USERS'),
    path: '/end-users',
    icon: () => <EndUserIcon />
  },
  {
    name: i18n.t('ROUTES.CART'),
    path: '/cart',
    icon: () => <CartIcon />
  },
  {
    name: i18n.t('ROUTES.PROFILE'),
    path: '/profile',
    icon: () => <UserIcon />
  }
  // {
  //   name: i18n.t('ROUTES.MANUAL'),
  //   path: '/manual',
  //   icon: () => <HelpIcon />
  // }
];
