import { action, configure, observable } from 'mobx';
import accountService from '../services/accountService';

configure({ enforceActions: 'observed' });

export type AccountBase = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  projects: string[];
  extendedMeasurement: boolean | string;
};

export interface Account extends AccountBase {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  projects: string[];
  extendedMeasurement: boolean | string;
}

export default class AccountStore {
  // STORE
  @observable account: Account = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    projects: [],
    username: '',
    extendedMeasurement: false
  };

  @observable pending: boolean = false;

  // ACTIONS

  @action
  private pendingStart = () => {
    this.pending = true;
  };
  @action
  private pendingEnd = () => {
    this.pending = false;
  };

  @action
  private setProfile = (account: Account) => {
    this.account = account;
  };

  @action
  getAccountAction = async () => {
    this.pendingStart();
    const result = await accountService.getAccount();
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.setProfile(result.user);
    return true;
  };

  @action
  deleteAccountAction = async () => {
    this.pendingStart();
    const result = await accountService.deleteAccount();
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  updateAccountAction = async (data: AccountBase) => {
    this.pendingStart();
    const result = await accountService.updateAccount({ ...data, type: 'MODERATOR', username: data.email });
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    this.getAccountAction();
    return true;
  };

  @action
  forgotPasswordAction = async (data: any) => {
    this.pendingStart();
    const result = await accountService.forgotPassword(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  resetPasswordAction = async (data: any) => {
    this.pendingStart();
    const result = await accountService.resetPassword(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  changePasswordAction = async (data: any) => {
    this.pendingStart();
    const result = await accountService.changePassword(data);
    this.pendingEnd();
    if (result.error) {
      return false;
    }
    return true;
  };

  @action
  clearAccountAction = () => {
    this.account = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      username: '',
      projects: [],
      extendedMeasurement: false
    };
  };
}
