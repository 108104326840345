import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles, Button } from '@material-ui/core';

import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import AppStore from '../../stores/appStore';
import AuthStore from '../../stores/authStore';
import { TextField } from 'formik-material-ui';
import Logo from '../../assets/images/sf_logo.svg';
import { Formik, FormikActions, Form, Field } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { EmailValidator, PasswordLoginValidator } from '../../utils/validators';
import Typography from '@material-ui/core/Typography';
import styles from './style';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

interface LoginProps extends WithTranslation, InjectedNotistackProps {}

interface InjectedProps extends LoginProps {
  authStore: AuthStore;
  appStore: AppStore;
  classes: any;
  location: any;
}

class LoginForm {
  email: string = '';
  password: string = '';
}

const LoginSchema = Yup.object().shape<LoginForm>({
  email: EmailValidator,
  password: PasswordLoginValidator
});

interface LoginState {
  redirectToReferrer: boolean;
  openModal: boolean;
  projects: string[];
}

@inject('authStore', 'appStore')
@observer
class Login extends Component<LoginProps, LoginState> {
  get p() {
    return this.props as InjectedProps;
  }

  state = {
    redirectToReferrer: false,
    openModal: false,
    projects: []
  };

  componentDidMount() {
    if (this.p.authStore.session && this.p.authStore.session.xsrfToken) {
      this.setState({ redirectToReferrer: true });
    }
  }

  openModal = () => {
    this.setState({
      openModal: true
    });
  };

  login = async (values: any) => {
    const result = await this.p.authStore.loginAction(values);
    if (result && result.error && !result.accessDenied) {
      this.props.enqueueSnackbar(this.p.t('ERRORS.WRONG_LOGIN'), {
        variant: 'error'
      });
    } else if (result && result.error && result.accessDenied) {
      this.props.enqueueSnackbar(this.p.t('ERRORS.LOGIN_FAIL'), {
        variant: 'error'
      });
    } else {
      const projects = await this.p.authStore.getProjectsAction();
      if (!projects || !projects.length) {
        this.props.enqueueSnackbar(this.p.t('CONTACT_WITH_ADMINISTRATION'), {
          variant: 'error'
        });
      } else if (projects.length === 1) {
        this.setState({ redirectToReferrer: true });
      } else {
        let projectList = projects.map((item: any) => item);
        this.setState({ projects: projectList });
        this.openModal();
      }
    }
  };

  render() {
    const { session, activeProject } = this.p.authStore;
    const { from } = this.p.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer || (session && session.xsrfToken && activeProject)) return <Redirect to={from} />;
    const {
      classes,
      t,
      authStore: { pending }
    } = this.p;
    return (
      <Formik
        initialValues={new LoginForm()}
        validationSchema={LoginSchema}
        onSubmit={(values: LoginForm, actions: FormikActions<LoginForm>) => {
          this.login(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {props => {
          const { isSubmitting, isValid } = props;
          return (
            <div className={classes.container}>
              <div className={classes.logo}>
                <img src={Logo} alt="Logo" />
              </div>
              <div className={classes.loginContainer}>
                <div className={classes.logoContainer}>
                  <Typography variant="h1" className={classes.title}>
                    SizeFit 3
                  </Typography>
                  <Typography variant="h2" className={classes.subtitle}>
                    {t('CLIENT')}
                  </Typography>
                </div>
                <div className={classes.content}>
                  <Form className={classes.center}>
                    <Field
                      id="email"
                      name="email"
                      label={t('LABELS.EMAIL')}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.control
                        }
                      }}
                      component={TextField}
                      className={classNames(classes.textField, classes.formControl)}
                      margin="normal"
                      autoComplete="off"
                      variant="outlined"
                    />
                    <Field
                      id="password"
                      name="password"
                      label={t('LABELS.PASSWORD')}
                      component={TextField}
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          root: classes.control
                        }
                      }}
                      className={classNames(classes.textFieldPassword, classes.formControl)}
                      margin="normal"
                      autoComplete="off"
                      type="password"
                      variant="outlined"
                    />
                    <div className={classes.forgotContainer}>
                      <Link to="/forgot-password">{t('LABELS.FORGOT_PASSWORD')}</Link>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid}
                      className={classNames(classes.button, classes.buttonWidth)}
                      type="submit"
                      data-cy="loginBtn"
                    >
                      {pending ? (
                        <CircularProgress size={20} thickness={5} color="secondary" />
                      ) : (
                        <div>{t('LABELS.LOGIN')}</div>
                      )}
                    </Button>
                  </Form>
                  {this.state.openModal && (
                    <>
                      <Dialog
                        open={this.state.openModal}
                        PaperProps={{
                          classes: {
                            root: classes.dialog
                          }
                        }}
                      >
                        <DialogTitle>{t('LOGIN.SET_PROJECT')}</DialogTitle>
                        <DialogActions className={classes.btnDialog}>
                          {this.state.projects.map((item: any) => (
                            <Button
                              onClick={() => this.p.authStore.setActiveProjectAction(item.id)}
                              className={classes.btn}
                              key={item.id}
                            >
                              {item.name}
                            </Button>
                          ))}
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(withTranslation()(withSnackbar(Login)));
